import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Tooltip from '../../../Common/Components/Tooltip';
import {
  CodingQuestion,
  FreeTextQuestions,
  MultipleChoiceQuestion,
  MultipleCorrectAnswer
} from './AssessmentQuestion';
import { calculateMin, renderDescription } from '../../../utils/helpers';
import { customAssessmentId } from '../../../redux/assessmentsVol2/selectors';
import {
  setRemoveQuestionShow,
  setQuestionBeingRemoved
} from '../../../redux/assessmentsVol2/slice';
import { ReactComponent as WorldIcon } from '../../../images/AddAssessment/AssessmentCustomization/world.svg';
import rightArrow from '../Images/right-arrow.svg';
import coding from '../../../images/AddAssessment/AssessmentCustomization/coding_assessment.svg';
import freetext from '../../../images/AddAssessment/AssessmentCustomization/freetext_assessment.svg';
import multiselect from '../../../images/AddAssessment/AssessmentCustomization/multiple_assessment.svg';
import multiplecorrect from '../../../images/AddAssessment/AssessmentCustomization/multiplecorrect_assessment.svg';
import questionDelete from '../Images/deleteMinus.svg';
import styles from '../Styles/FilteredQuestion.module.css';
import TextOverflow from '../../../Common/Components/TextOverflow';

const HMViewQuestion = ({ question, index, wrapperWidth, setEditDurationQuestionData }) => {
  const dispatch = useDispatch();
  const {
    title,
    description,
    // isIncluded,
    questionId,
    category,
    // purchaseType,
    questionLanguage,
    questionDuration,
    questionType,
    questionTypeId,
    // codingLanguages,
    options,
    imageOption,
    label,
    isCustom // TODO: Update this one
  } = question;
  const assID = useSelector(customAssessmentId);

  const [questionBody, setQuestionBody] = useState();
  const [showEditButton, setShowEditButton] = useState(false);

  function parseDescription() {
    try {
      const innerBody = JSON.parse(description);
      setQuestionBody(innerBody);
    } catch (error) {
      console.log('error');
    }
  }

  useEffect(() => {
    parseDescription();
  }, [description]);

  const [qDescription, setqDescription] = useState();
  const [questionDescShown, setQuestionDescShown] = useState(false);

  const deleteQuestion = () => {
    const data = {
      rootAssessmentId: assID,
      questionId: questionId
    };
    dispatch(setRemoveQuestionShow(true));
    dispatch(setQuestionBeingRemoved(data));
  };

  const editDuration = () => {
    if (questionType === 2) {
      setShowEditButton(false);
    }
  };

  const displayQuestion = type => {
    switch (type) {
      case 1:
        return (
          <MultipleChoiceQuestion
            mcOptions={options}
            qDesc={qDescription}
            isDetailedShown={false}
            imageOption={imageOption}
          />
        );
      case 2:
        return <CodingQuestion mcOptions={options} qDesc={qDescription} isDetailedShown={false} />;
      case 3:
        return (
          <FreeTextQuestions mcOptions={options} qDesc={qDescription} isDetailedShown={false} />
        );
      case 4:
        return (
          <MultipleCorrectAnswer
            mcOptions={options}
            qDesc={qDescription}
            isDetailedShown={false}
            imageOption={imageOption}
          />
        );
      default:
        return null;
    }
  };

  useEffect(() => {
    let qBodyTemp = questionBody;
    qBodyTemp = qBodyTemp?.replaceAll('https://api.coens.io/', '');
    qBodyTemp = qBodyTemp?.replaceAll('https://file.coens.io/', '');
    qBodyTemp = qBodyTemp?.replaceAll(
      '<img src="',
      `<img src="${process.env.REACT_APP_IMAGE_API}/`
    );
    qBodyTemp = qBodyTemp?.replaceAll(
      '<source src="',
      `<source src="${process.env.REACT_APP_IMAGE_API}/`
    );
    setqDescription(qBodyTemp);
  }, [questionBody]);

  useEffect(() => {
    if (questionDescShown) {
      const element = document.getElementById('questionBasedLeftColumn');
      const scrolledQuestion = document.getElementById(`to-scrolled-${index}`);
      const secondRow = document.getElementById('header');

      if (element) {
        element.scrollBy({
          top:
            scrolledQuestion?.getBoundingClientRect().top -
            secondRow?.getBoundingClientRect().bottom -
            50,
          behavior: 'smooth'
        });
      }
    }
  }, [questionDescShown]);

  return (
    <div
      id={`to-scrolled-${index}`}
      className={`FlexColumn gap-6 w-100 ${styles.singleQuestionWrapper}`}
      style={{ maxWidth: wrapperWidth }}
    >
      {/* Title row */}
      <div className="FlexRow gap-6 verticallyCenter">
        <span className="fw-400 font-16 c-lightGray">{index + 1}.</span>
        <TextOverflow className="fw-400 font-16 c-lightGray" text={title} />
        {questionLanguage !== 'EN' && (
          <div className="FlexRow verticallyCenter gap-4" style={{ marginLeft: '8px' }}>
            <WorldIcon style={{ width: '15px', height: '15px' }} />
            <div
              className="FlexRow verticallyCenter fw-300 font-14"
              style={{ color: 'rgba(88, 88, 88, 0.5)' }}
            >{`${questionLanguage}`}</div>
          </div>
        )}

        <div className="FlexRow verticallyAlign horizontallyCenter" style={{ marginLeft: 'auto' }}>
          <span className={`pointer ${styles.imgWrapper2}`} onClick={deleteQuestion}>
            <img style={{ width: 24, height: 24 }} alt="" src={questionDelete} />
          </span>
        </div>
      </div>

      {/* Description row */}
      <div
        style={{
          marginBottom: '0px',
          background: 'rgba(244, 244, 244, 1)',
          padding: '7px',
          borderRadius: '8px'
        }}
        className="FlexRow spaceBetween verticallyCenter pointer overflowAuto"
        onClick={() => setQuestionDescShown(!questionDescShown)}
      >
        <div
          className="FlexRow spaceBetween verticallyCenter gap-12 c-darkGray fw-400 font-15 w-100"
          style={{ padding: '7px' }}
        >
          {questionDescShown ? (
            <div
              style={{
                display: 'block',
                maxHeight: '440px',
                visibility: 'visible',
                opacity: 1,
                transition: '0.8s ease-in-out'
              }}
            >
              {displayQuestion(questionType)}
            </div>
          ) : (
            <div
              className={styles.descriptionShort}
              id="hm-view-q-desc-overflow"
              style={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}
            >
              {renderDescription(qDescription)}
            </div>
          )}

          <img
            className="pointer"
            style={{
              width: '8px',
              height: '12px',
              alignSelf: 'flex-start',
              transition: 'transform 0.4s ease-out',
              marginTop: 'calc(1rem + 4px)',
              marginRight: '8px',
              transform: questionDescShown ? 'rotateZ(90deg)' : 'unset'
            }}
            alt=""
            src={rightArrow}
          />
        </div>
      </div>

      {/* Bottom row */}
      <div
        className="FlexRow h-100 verticallyCenter c-lightGray"
        style={{
          paddingTop: '6px'
        }}
      >
        <span style={{ marginRight: '4px' }}>
          <img
            alt=""
            width={36}
            height={24}
            src={
              questionType === 2
                ? coding
                : questionType === 3
                ? freetext
                : questionType === 4
                ? multiplecorrect
                : multiselect
            }
          />
        </span>
        {questionType !== 2 ? (
          <div className={styles.skillWrapper}>{category}</div>
        ) : (
          label?.slice(0, 3)?.map(lang => <div className={styles.skillWrapper}>{lang}</div>)
        )}

        {questionType === 2 && label?.length >= 4 ? (
          <Tooltip
            direction="right"
            theme="light"
            content={
              <ul className={styles.skillList}>
                {label?.slice(3, label?.length).map(x => (
                  <li className={styles.skillElement}>
                    <span>{x}</span>
                  </li>
                ))}
              </ul>
            }
          >
            <div className={`${styles.skillWrapper} ${styles.more}`}>{`+${label?.length - 3}`}</div>
          </Tooltip>
        ) : null}

        <div
          className={`FlexRow ml-auto ${styles.skillWrapper}`}
          style={{ backgroundColor: 'rgba(116, 88, 211, 0.12)' }}
        >
          {isCustom ? 'My Company Library' : 'Coensio Library'}
        </div>

        {questionType !== 2 && (
          <div
            className={`FlexRow gap-6 hoverable pointer ${styles.skillWrapper}`}
            onClick={() => setEditDurationQuestionData(question)}
          >
            <div className={styles.duration}>{calculateMin(questionDuration)}</div>
            <EditIcon />
          </div>
        )}
        {questionType === 2 && (
          <div className={`FlexRow gap-6 ${styles.skillWrapper}`}>
            <div className={styles.duration}>{calculateMin(questionDuration)}</div>
          </div>
        )}
      </div>
    </div>
  );
};

export default HMViewQuestion;

const EditIcon = ({ props }) => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M5.65543 10.8651L10.2309 6.28957C10.3564 6.16405 10.56 6.16405 10.6855 6.28957L11.8977 7.50175C12.0232 7.62728 12.0232 7.8308 11.8977 7.95632L7.32218 12.5318C7.26531 12.5887 7.1892 12.6222 7.10886 12.6257L5.84157 12.6808C5.65322 12.6889 5.4983 12.534 5.50649 12.3457L5.56159 11.0784C5.56508 10.998 5.59856 10.9219 5.65543 10.8651Z"
      fill="#7458D3"
      stroke="#7458D3"
      stroke-width="0.642857"
    />
    <rect
      x="11.1782"
      y="5.34253"
      width="0.857143"
      height="2.35714"
      rx="0.321429"
      transform="rotate(-45 11.1782 5.34253)"
      fill="white"
      stroke="#7458D3"
      stroke-width="0.642857"
    />
  </svg>
);
