import React, { createContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import 'react-dropdown/style.css';
import FunctionSignature from '../Components/functionSignature';
import QuestionDetails from '../Components/questionDetails';
import TestCases from '../Components/testCases';
import VerificationCode from '../Components/verificationCode';
import {
  apiFormatterForInputs,
  apiFormatterForOutput,
  languages
} from '../Constants/codingLanguageConstants';

import styles from '../Styles/Coding.module.css';
import CloseButton from '../../../Common/Components/CloseButton';
import {
  createCodingQuestionAction2
} from '../../../redux/addQuestion/actions';
import {
  assessmentLabelRedux,
  categoryRedux,
  difficultyRedux,
  initialCodeDictionaryRedux,
  isPassedVerificationRedux,
  isSuccessRedux,
  languageRedux,
  limitRedux,
  outputVariableRedux,
  parametersRedux,
  questionDescriptionRedux,
  statusRedux,
  subcategoryRedux,
  testCasesRedux,
  titleRedux,
  validCasesRedux,
  verificationCodeRedux
} from '../../../redux/addQuestion/selectors';
import { setInitial } from '../../../redux/addQuestion/slice';
import InformationPopup from '../Modals/InformationPopup';
import QuestionDescTextEditor from '../Components/QuestionDescTextEditor';
import Header from '../../../Common/Components/Header';

export const UserContext = createContext();

function CodingQuestionPage() {
  const dispatch = useDispatch();

  // Selectors
  const reduxCategory = useSelector(categoryRedux);
  const reduxAssessmentLabel = useSelector(assessmentLabelRedux);
  const reduxSubcategory = useSelector(subcategoryRedux);
  const reduxTitle = useSelector(titleRedux);
  const reduxDifficulty = useSelector(difficultyRedux);
  const reduxLanguage = useSelector(languageRedux);
  const reduxLimit = useSelector(limitRedux);
  const reduxStatus = useSelector(statusRedux);

  const reduxQuestionDescription = useSelector(questionDescriptionRedux);

  const reduxIsSuccess = useSelector(isSuccessRedux);

  const reduxOutputVariable = useSelector(outputVariableRedux);
  const reduxParameters = useSelector(parametersRedux);
  const reduxTestCases = useSelector(testCasesRedux);
  const reduxValidCases = useSelector(validCasesRedux);
  const reduxIsPassedVerification = useSelector(isPassedVerificationRedux);
  const reduxInitialCodeDictionary = useSelector(initialCodeDictionaryRedux);
  const reduxVerificationCode = useSelector(verificationCodeRedux);

  // const [outputVariable, setOutputVariable] = useState({
  //   type: '',
  //   name: 'undefined'
  // });
  // const [parameters, setParameters] = useState([{ type: '', name: '' }]);

  /// ///////////////////////////////////////////////////////////////////
  const [questionStage, setQuestionStage] = useState(1);
  // const [htmlContent, setHtmlContent] = useState('');
  const [isInQuestionDetail, setInQuestionDetail] = useState(false);

  const [saveStatus, setSaveStatus] = useState(0);
  const [showPopup, setShowPopup] = useState(false);

  /* eslint-disable */
  function checkSaveStatus() {
    let bool = true;
    if (reduxOutputVariable.type === '' || reduxOutputVariable.name === '') {
      bool = false;
      return bool;
    }
    if (reduxTestCases.length === 0) {
      bool = false;
      return bool;
    } else {
      reduxTestCases.forEach(element => {
        if (element.weight === '' || element.result === '') {
          bool = false;
          return bool;
        }
        element.input.forEach(e => {
          if (e === '') {
            bool = false;
            return bool;
          }
        });
      });
    }

    if (reduxValidCases.length === 0) {
      bool = false;
      return bool;
    } else {
      reduxValidCases.forEach(element => {
        if (element.weight === '' || element.result === '') {
          bool = false;
          return bool;
        }
        element.input.forEach(e => {
          if (e === '') {
            bool = false;
            return bool;
          }
        });
      });
    }

    if (
      !reduxCategory ||
      reduxCategory === '' ||
      // !reduxAssessmentLabel ||
      // reduxAssessmentLabel === '' ||
      // !reduxSubcategory ||
      // reduxSubcategory === '' ||
      !reduxTitle ||
      reduxTitle === '' ||
      !reduxDifficulty ||
      reduxDifficulty === '' ||
      !reduxLanguage ||
      reduxLanguage === '' ||
      !reduxIsPassedVerification ||
      !reduxQuestionDescription ||
      reduxQuestionDescription === '' ||
      reduxQuestionDescription.trim() === '<p></p>'
    ) {
      bool = false;
      return bool;
    }
    return bool;
  }

  useEffect(async () => {
    checkSaveStatus();
    setSaveStatus(0);
  }, [saveStatus]);

  const createQuestion = async () => {
    let questionBody = reduxQuestionDescription;
    questionBody = questionBody.replaceAll('"', '\\"');
    const questionBodyArray = questionBody.split('<p>');
    let updatedQuestionBody = '"';
    questionBodyArray.forEach((element, index) => {
      if (index === questionBodyArray.length - 1) {
        updatedQuestionBody = updatedQuestionBody.concat(element.trimEnd(' '));
      } else {
        updatedQuestionBody = updatedQuestionBody.concat(element);
        updatedQuestionBody = updatedQuestionBody.concat(
          '<p class=\\"text-dark-75 font-size-xlg font-weight-normal pt-5 mb-1\\">'
        );
      }
    });
    updatedQuestionBody = updatedQuestionBody.concat('"');
    updatedQuestionBody = updatedQuestionBody.replaceAll('>\n<', '><');

    const testMainCodeArr = [];
    const validMainCodeArr = [];
    languages.forEach(e => {
      const innerDictTest = {};
      const innerDictValid = {};

      innerDictTest.inputCount = reduxTestCases.length;
      innerDictValid.inputCount = reduxValidCases.length;
      innerDictTest.outputType = reduxOutputVariable.type;
      innerDictValid.outputType = reduxOutputVariable.type;
      innerDictTest.testCount = reduxTestCases.length;
      innerDictValid.testCount = reduxValidCases.length;
      innerDictTest.codeLanguage = e.value;
      innerDictValid.codeLanguage = e.value;
      innerDictTest.initialCode = reduxInitialCodeDictionary[e.value];
      innerDictValid.initialCode = reduxInitialCodeDictionary[e.value];

      const testOutputs = apiFormatterForOutput(reduxTestCases, e.value, reduxOutputVariable);
      const testInputsArray = apiFormatterForInputs(
        testOutputs.inputsArrayRaw,
        e.value,
        reduxOutputVariable,
        reduxParameters
      );
      innerDictTest.inputs = testInputsArray;
      innerDictTest.outputs = testOutputs.outputsArray;
      innerDictTest.weights = testOutputs.weightsArray;

      const validOutputs = apiFormatterForOutput(reduxValidCases, e.value, reduxOutputVariable);
      const validInputsArray = apiFormatterForInputs(
        validOutputs.inputsArrayRaw,
        e.value,
        reduxOutputVariable,
        reduxParameters
      );
      innerDictValid.inputs = validInputsArray;
      innerDictValid.outputs = validOutputs.outputsArray;
      innerDictValid.weights = validOutputs.weightsArray;

      testMainCodeArr.push(innerDictTest);
      validMainCodeArr.push(innerDictValid);
    });

    const rawData = {
      questionTitle: reduxTitle,
      time: reduxLimit,
      category: reduxCategory,
      subCategory: reduxTitle,
      assessmentLabel: "General Coding",
      questionDifficulty: reduxDifficulty,
      questionLanguage: reduxLanguage,
      questionDescHtml: updatedQuestionBody,
      answerCode: reduxVerificationCode,
      isActive: reduxStatus,
      testMainCodeCreateCommands: testMainCodeArr,
      validationMainCodeCreateCommands: validMainCodeArr
    };
    const a = await dispatch(createCodingQuestionAction2(rawData));
  };

  useEffect(async () => {
    if (questionStage === 4) {
      setInQuestionDetail(true);
    } else {
      setInQuestionDetail(false);
    }
  }, [questionStage]);

  function nextClicked() {
    setQuestionStage(questionStage + 1);
  }

  function backClicked() {
    if (questionStage !== 1) {
      setQuestionStage(questionStage - 1);
    }
  }

  function closeQuestion(e) {
    e.stopPropagation();
    alert("You're about to leave the page. Your work will be lost !");
    dispatch(setInitial());
    setQuestionStage(1);
  }

  function saveClicked() {
    setShowPopup(true);
    createQuestion();
  }

  // const getContent = htmlContentProp => {
  //   setHtmlContent(htmlContentProp);
  // };

  const popupOnHide = () => {
    switch (reduxIsSuccess) {
      case true:
        dispatch(setInitial());
        setQuestionStage(1);
        setShowPopup(false);
        break;
      case false:
        setShowPopup(false);
        break;
      default:
        break;
    }
  };

  return (
    <div>
      {/* <QuestionHeader /> */}
      <Header />
      <InformationPopup show={showPopup} onHide={popupOnHide} />
      <div className={styles.QuestionWrapperPane}>
        <div className={styles.QuestionHeaderRow}>
          <div className={styles.QuestionHeader}>Add Coding Question</div>
          <CloseButton
            onClick={e => {
              closeQuestion(e);
            }}
            size="large"
          />
        </div>
        <div className={styles.QuestionPane}>
          <div className={styles.LeftPane}>
            <div className={styles.height30px}>Question Text:</div>
            <div className={styles.TextEditorQuestionText}>
              <QuestionDescTextEditor />
            </div>
          </div>
          <div className={styles.toolbarBorder} />
          {questionStage === 1 ? <FunctionSignature /> : null}
          {questionStage === 2 ? <TestCases /> : null}
          {questionStage === 3 ? <VerificationCode /> : null}
          {questionStage === 4 ? <QuestionDetails detailChange={setSaveStatus} /> : null}
        </div>
        <div className={styles['nav-question']}>
          <button
            className={styles.questionNavButton}
            type="button"
            onClick={backClicked}
            style={{ gridColumnStart: 2 }}
          >
            BACK
          </button>
          {isInQuestionDetail ? (
            <button
              disabled={!checkSaveStatus()}
              className={styles.questionNavButton}
              type="button"
              onClick={saveClicked}
              style={{ gridColumnStart: 3 }}
            >
              SAVE
            </button>
          ) : (
            <button
              className={styles.questionNavButton}
              type="button"
              disabled={questionStage === 3 && !reduxIsPassedVerification}
              onClick={nextClicked}
              style={{ gridColumnStart: 3 }}
            >
              NEXT
            </button>
          )}
        </div>
      </div>
    </div>
  );
}

export default CodingQuestionPage;
