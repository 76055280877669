import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import CustomButton from '../../../../Common/Components/CustomButton';
import tick from '../../../../images/Common/successSubmit.svg';
import { userSelector } from '../../../../redux/auth/selectors';
import { getHrPeakTestResultsAction, sendHrPeakTestToCandidateAction } from '../../../Api/action';

export default function ProficiencyCard() {
  const userRedux = useSelector(userSelector);

  const { id } = useParams();

  const IS_PROF_COMPANY = userRedux?.companyName === 'Brisa';
  /**
   * 1 - show send
   * 2 - sent!
   * 3 - NA
   * 4 - show report
   */
  const [status, setStatus] = useState();
  const [reportLink, setReportLink] = useState();
  const SHOW_PROF = IS_PROF_COMPANY && status;

  async function getProficiencyResults() {
    const data = {
      userId: id
    };
    const resp = await getHrPeakTestResultsAction(data);
    if (resp?.status === 200) {
      const respData = resp?.data;
      if (respData?.reportLink) {
        setReportLink(respData?.reportLink);
        setStatus(4);
      } else {
        setStatus(3);
      }
    } else if (resp?.status === 204) {
      setStatus(1);
    }
  }

  useEffect(() => {
    if (IS_PROF_COMPANY) {
      getProficiencyResults();
    }
  }, [userRedux]);

  const { t } = useTranslation(['candidateDetail'])

  return (
    <div
      className="FlexColumn gap-20"
      style={{
        padding: '20px 24px 24px',
        borderRadius: '10px',
        background: '#fff',
        boxShadow: '0px 2px 24px 0px rgba(134, 134, 134, 0.15)',
        display: IS_PROF_COMPANY ? '' : 'none'
      }}
    >
      {SHOW_PROF ? (
        <span className="c-darkGray fw-500 font-18">{t('engTest')}</span>
      ) : (
        <span className="skeletonAnimation" style={{ borderRadius: '10px', height: '40px' }} />
      )}

      {status === 1 ? (
        <ShowSendButton setStatus={setStatus} />
      ) : status === 2 ? (
        <ShowAssessmentSent />
      ) : status === 3 ? (
        <NotSolvedYet />
      ) : status === 4 ? (
        <ShowSeeResults reportLink={reportLink} />
      ) : null}
    </div>
  );
}

const ShowSendButton = ({ setStatus }) => {
  const { t } = useTranslation(['candidateDetail'])
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const sendTest = async () => {
    const data = {
      userId: id
    };
    setLoading(true);

    const resp = await sendHrPeakTestToCandidateAction(data);
    if (resp?.status === 200) {
      setStatus(2);
    }
    setLoading(false);
  };

  return (
    <CustomButton
      type={1}
      size="small"
      textField={t('sendEngTest')}
      customStyle={{ width: '100%', height: '42px' }}
      buttonOnClick={sendTest}
      loading={loading}
    />
  );
};

const ShowAssessmentSent = () => {
  const { t } = useTranslation(['candidateDetail'])

  return (
    <div className="FlexRow gap-12 verticallyCenter">
      <img src={tick} alt="successfully_sent" width={16} height={16} />
      <span className="fw-400 font-16" style={{ color: '#4AC389' }}>
        {t('testSend')}
      </span>
    </div>
  );
};

const NotSolvedYet = () => {
  const { t } = useTranslation(['candidateDetail'])

  return (
    <div className="FlexColumn gap-12">
      <div className="FlexRow gap-5 c-darkGray verticallyCenter">
        <div
          style={{ width: '12px', height: '12px', borderRadius: '50%', background: '#393939' }}
        />
        <span className="font-15">N/A</span>
      </div>
      <span className="fw-400 font-15 c-darkGray-05">{t('notSolveYet')}</span>
    </div>
  );
};

const ShowSeeResults = ({ reportLink }) => {
  const seeResults = () => {
    window.open(reportLink, '_blank').focus();
  };

  const { t } = useTranslation(['candidateDetail'])

  return (
    <CustomButton
      type={5}
      size="small"
      textField={t('seeReport')}
      customStyle={{ width: '100%', height: '42px' }}
      buttonOnClick={seeResults}
    />
  );
};
