import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import CloseButton from '../../../Common/Components/CloseButton';
import CustomButton from '../../../Common/Components/CustomButton';
import styles from './FreeTrialModal.module.css';
import icon from './freeTrialIcon.svg';
import { userSelector } from '../../../redux/auth/selectors';
import { setShowPackageDetail } from '../../../redux/plan/slice';
import mixpanel from '../../../utils/mixpanel';
import { currentPlanRedux } from '../../../redux/plan/selectors';
import { mixpanelReturnUserRelatedProperties } from '../../../utils/mixpanelHelpers';
import Modal from '../../../Common/CustomModal/Modal';

export default function FreeTrialModal({ show, onHide = () => {}, addOnCode, ...props }) {
  const dispatch = useDispatch();
  const user = useSelector(userSelector);

  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const { t } = useTranslation(['addPosition'])
  const mixpanelEventHandler = eventName => {
    const userProps = mixpanelReturnUserRelatedProperties(user);
    mixpanel.track(eventName, { ...userProps });
  };
  async function handleButtonClick() {

    if (addOnCode === 40) {
      mixpanelEventHandler('Unlock - Free Trial - Show Package Details Button Click')
    }

    await onHide();
    await dispatch(setShowPackageDetail(true));
  }

  useEffect(() => {
    if (addOnCode === 10) {
      // Assessment
      setTitle(t('freeTrialModal'));
      setDescription(t('paidPlansHave'));
    } else if (addOnCode === 30) {
      // Candidate Report
      setTitle(t('checkOut'));
      setDescription(
        t('unlockMore')
      );
    } else if (addOnCode === 60 || addOnCode === 65 || addOnCode === 61) {
      // Premium Question
      setTitle(t('premimumQ'));
      setDescription(
        t('premiumQWithPaidPlan')
      );
    } else if (addOnCode === 63 || addOnCode === 62 || addOnCode === 67 || addOnCode === 66) {
      // Assessment with premium questions
      setTitle(t('includePremiumQ'));
      setDescription(
        t('premiumQWithPaidPlan')
      );
    } else if (addOnCode === 40) {
      // Locked candidate
      setTitle(t('checkOut!'));
      setDescription(
        t('moreCandPaidPlan')
      );
    } else if (addOnCode === 70) {
      // Request candidate
      setTitle(t('checkOut!'));
      setDescription(t('reachMoreCandPaidPlan'));
    }
  }, [addOnCode]);

  /* ---- Mixpanel pageview ----- */
  const currentPlan = useSelector(currentPlanRedux);

  useEffect(() => {
    if (show && currentPlan) {
      if (addOnCode === 10) {
        // Assessment
        mixpanel.track('Free Trial Popup - Pageview', {
          'User Id': user.userId,
          'Company Name': user.companyName,
          'Name Surname': user.userNameSurname,
          'User Role': user.userGroupType,
          'Is Admin Flag': user.isAdmin,
          'Plan Name': currentPlan?.name,
          Type: 'Published Assessment'
        });
      } else if (addOnCode === 30) {
        // Candidate Report
        mixpanel.track('Free Trial Popup - Pageview', {
          'User Id': user.userId,
          'Company Name': user.companyName,
          'Name Surname': user.userNameSurname,
          'User Role': user.userGroupType,
          'Is Admin Flag': user.isAdmin,
          'Plan Name': currentPlan?.name,
          Type: 'Candidate Report'
        });
      } else if (addOnCode === 60 || addOnCode === 65 || addOnCode === 61) {
        // Premium Question
        mixpanel.track('Free Trial Popup - Pageview', {
          'User Id': user.userId,
          'Company Name': user.companyName,
          'Name Surname': user.userNameSurname,
          'User Role': user.userGroupType,
          'Is Admin Flag': user.isAdmin,
          'Plan Name': currentPlan?.name,
          Type: 'Premium Question'
        });
      } else if (addOnCode === 63 || addOnCode === 62 || addOnCode === 67 || addOnCode === 66) {
        // Assessment with premium questions
        mixpanel.track('Free Trial Popup - Pageview', {
          'User Id': user.userId,
          'Company Name': user.companyName,
          'Name Surname': user.userNameSurname,
          'User Role': user.userGroupType,
          'Is Admin Flag': user.isAdmin,
          'Plan Name': currentPlan?.name,
          Type: 'Premium Question in Assessment'
        });
      } else if (addOnCode === 40) {
        mixpanelEventHandler('Unlock - Free Trial Popup - Pageview')
      }
    }
  }, [show, addOnCode]);
  /* ------------------------------- */

  return (
    <Modal
      show={show}
      contentClassName={`${styles.modalContent} ${styles['modal-content']}`}
      centerHorizontally
      centerVertically
      outsideClickClose
      onClose={onHide}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          height: '100%'
        }}
      >
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <CloseButton size="small" onClick={() => onHide()} />
          <span className={styles.successIcon}>
            <img src={icon} alt="" />
          </span>

          <div className={styles.title}>{title}</div>
          <div className={styles.textWrapper}>
            <div className={styles.subtitle} style={{ marginRight: '12px' }}>
              {description}
            </div>
          </div>
        </div>
        <CustomButton
          style={{ width: 'calc(100% - 12px)', marginRight: '12px' }}
          type={1}
          textField={t('seePlans')}
          buttonOnClick={() => {
            handleButtonClick();
          }}
        />
      </div>
    </Modal>
  );
}
