import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './RegenerateModal.module.css';
import Modal from '../../../../../Common/CustomModal/Modal';
import CustomButton from '../../../../../Common/Components/CustomButton';
import CloseButton from '../../../../../Common/Components/CloseButton';

const RegenerateModal = ({
  show,
  onClose,
  setAllAsRegenerated,
  questionId = null,
  setQuestionAsRegenerated
}) => {
  const [innerShow, setInnerShow] = useState(show);
  const [comment, setComment] = useState('');

  useEffect(() => {
    if (show) {
      setInnerShow(true);
    } else {
      setTimeout(() => {
        setInnerShow(false);
      }, 300);
      setComment('');
    }
  }, [show]);

  const { t } = useTranslation(['assmCustomization'])

  const ModalContent = (
    <div className={styles.RegenerateModalWrapper}>
      <div className={styles.RegenerateModalTitleRow}>
        <span>{questionId ? t('regenerateQ') : t('regenerateAssm')}</span>
        <CloseButton onClick={onClose} width={24} height={24} />
      </div>
      <div className={styles.RegenerateModalBody}>
        {questionId ? (
          <textarea
            id="regenerateAssessmentTextArea"
            maxLength={300}
            className={styles.RegenerateModalTextArea}
            placeholder={t('regenerateQFeedback')}
            value={comment}
            onChange={e => setComment(e?.target?.value)}
          />
        ) : (
          <textarea
            id="regenerateAssessmentTextArea"
            maxLength={300}
            className={styles.RegenerateModalTextArea}
            placeholder={t('regenerateAssmFeedback')}
            value={comment}
            onChange={e => setComment(e?.target?.value)}
          />
        )}
      </div>
      <div className={styles.RegenerateModalBottomRow}>
        <CustomButton
          customStyle={{ fontSize: '16px' }}
          textField={t('cancel')}
          type={2}
          size="medium"
          buttonOnClick={onClose}
        />
        <CustomButton
          customStyle={{ fontSize: '16px' }}
          textField={t('confirm')}
          size="medium"
          buttonOnClick={() => {
            if (questionId) {
              setTimeout(() => {
                setQuestionAsRegenerated(comment);
              }, 250);
            } else {
              setAllAsRegenerated(comment);
            }
            onClose();
          }}
        />
      </div>
    </div>
  );

  const WebModalWrapper = (
    <Modal
      modalId="regenerateAssessment"
      show={show}
      onClose={onClose}
      outsideClickClose
      centerHorizontally
      centerVertically
      size={{ width: '600px', height: 'fit-content' }}
      contentStyle={{ borderRadius: '16px' }}
    >
      {ModalContent}
    </Modal>
  );

  return innerShow ? WebModalWrapper : null;
};

export default RegenerateModal;
