import React, { useEffect, useState } from 'react';

import Checkbox from '../../../../Common/RawCheckbox/Checkbox';
import styles from './AdditionalScreeningCard.module.css';
import { useWindowSize } from '../../../../utils/helpers';

const AdditionalMultiSelect = ({ question, handleMultiSelect, previousResponse }) => {
  const [selectedArr, setSelectedArr] = useState([]);

  const handleClick = option => {
    if (selectedArr.includes(option)) {
      setSelectedArr(selectedArr.filter(e => e !== option));
    } else {
      setSelectedArr([...selectedArr, option]);
    }
  };

  useEffect(() => {
    handleMultiSelect(question.questionId, selectedArr);
  }, [selectedArr]);

  useEffect(() => {
    if (previousResponse) {
      setSelectedArr(JSON.parse(previousResponse));
    }
  }, [previousResponse]);

  return (
    <div className={styles.questionWrapper}>
      <div className={styles.question}>
        {question.title} {question.isMandatory && <span style={{ color: 'red' }}>*</span>}
      </div>
      <div className={styles.wrapper}>
        {question.options?.map(e => (
          <MultiSelectCheckbox
            key={e.id}
            text={e}
            isSelected={selectedArr.includes(e)}
            handleClick={handleClick}
          />
        ))}
      </div>
    </div>
  );
};

const MultiSelectCheckbox = ({ text, isSelected, handleClick }) => {
  const [screenW] = useWindowSize();

  return (
    <div
      className={styles.checkboxWrapper}
      onClick={() => {
        handleClick(text);
      }}
      role="none"
    >
      <Checkbox isChecked={isSelected} size={screenW < 576 ? 19 : 16} />
      <div className={styles.text}>{text}</div>
    </div>
  );
};

export default AdditionalMultiSelect;
