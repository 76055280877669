import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import MobileModal from '../../../Common/Components/MobileModal/MobileModal';
import Modal from '../../../Common/CustomModal/Modal';
import TextOverflow from '../../../Common/Components/TextOverflow';
import {
  currentAssessmentInfoSelector,
  defaultAssessmentFunnelListSelector
} from '../../../redux/candidateDetail/selectors';
import { useWindowSize } from '../../../utils/helpers';
import styles from './ChangeFunnelModal.module.css';
import { moveCandidateToFunnel, sortOnPriority } from './helpers';

const ChangeFunnelModal = ({ show, onClose }) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [windowW] = useWindowSize();
  const defaultAssessmentFunnelListRedux = useSelector(defaultAssessmentFunnelListSelector);
  const currentAssessmentInfoRedux = useSelector(currentAssessmentInfoSelector);
  const [funnelList, setFunnelList] = useState();

  const [loadingState, setLoadingState] = useState(false);

  const { t } = useTranslation(['candidateDetail']);

  useEffect(() => {
    if (defaultAssessmentFunnelListRedux && defaultAssessmentFunnelListRedux.length > 0) {
      let temp = [...defaultAssessmentFunnelListRedux].sort(sortOnPriority);
      const isScored = currentAssessmentInfoRedux.score !== -1;
      temp = temp.filter(e =>
        isScored
          ? e.funnelName !== 'Not Scored' && e.funnelName !== 'Rejected'
          : e.funnelName !== 'Scored' && e.funnelName !== 'Rejected'
      );
      const funnelListForDropdown = [];
      temp.forEach(e =>
        funnelListForDropdown.push({
          value: e.funnelId,
          label: e.funnelName,
          category: e.funnelCategory
        })
      );
      setFunnelList(funnelListForDropdown);
    }
  }, [defaultAssessmentFunnelListRedux]);

  /* Funnel update for default selected assessment on click */
  function moveCandidateToFunnelInner(selectedFunnel) {
    setLoadingState(true);
    moveCandidateToFunnel(dispatch, id, selectedFunnel, true, t);
  }

  function closeModal() {
    onClose();
    setLoadingState(false);
  }

  useEffect(() => {
    window.addEventListener('candidateFunnelUpdateOnCandidateDetail', closeModal);
    return () => {
      window.removeEventListener('candidateFunnelUpdateOnCandidateDetail', closeModal);
    };
  });

  const WebModal = (
    <Modal
      modalId="changeFunnelModal"
      show={show}
      onClose={onClose}
      centerHorizontally
      centerVertically
      contentStyle={{ borderRadius: '10px', padding: '16px 10px 16px 20px', width: '368px' }}
      outsideClickClose
    >
      <div
        className={styles.wrapper}
        style={loadingState ? { opacity: '0.4', pointerEvents: 'none' } : {}}
      >
        <text className={styles.headerText}>Change Stage</text>
        <div className={styles.funnelWrapper}>
          {funnelList?.map(e => (
            <div
              className={styles.individualFunnelWrapper}
              style={funnelList.length <= 5 ? { width: '328px' } : {}}
              onClick={() => moveCandidateToFunnelInner(e)}
            >
              <div
                className={styles.individualFunnel}
                style={
                  currentAssessmentInfoRedux?.currentFunnel === e.label
                    ? { background: '#7458D3', color: '#ffffff' }
                    : {}
                }
              >
                <TextOverflow text={e.label} />
              </div>
            </div>
          ))}
        </div>
      </div>
    </Modal>
  );

  const MobileFunnelModal = (
    <MobileModal
      id="changeFunnelModalMobile"
      show={show}
      onClose={onClose}
      outsideClickClose
      contentStyle={{ padding: '20px 10px 20px 20px', minWidth: '375px' }}
      backdropStyle={{ overflowY: 'hidden' }}
    >
      <div
        className={styles.wrapper}
        style={loadingState ? { opacity: '0.4', pointerEvents: 'none' } : {}}
      >
        <text className={styles.headerText}>Change Stage</text>
        <div className={styles.funnelWrapper}>
          {funnelList?.map(e => (
            <div
              className={styles.individualFunnelWrapper}
              style={funnelList.length <= 5 ? { width: '328px' } : {}}
              onClick={() => moveCandidateToFunnelInner(e)}
            >
              <div
                className={styles.individualFunnel}
                style={
                  currentAssessmentInfoRedux?.currentFunnel === e.label
                    ? { background: '#7458D3', color: '#ffffff' }
                    : {}
                }
              >
                <TextOverflow text={e.label} />
              </div>
            </div>
          ))}
        </div>
      </div>
    </MobileModal>
  );

  return (
    <div className={styles.container}>
      {windowW > 576 ? <>{WebModal}</> : <>{MobileFunnelModal}</>}
    </div>
  );
};
export default ChangeFunnelModal;
