import React, { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Modal from '../../../../../Common/CustomModal/Modal';

import styles from './RemoveQuestionModal.module.css';
import CloseButton from '../../../../../Common/Components/CloseButton';
import CustomButton from '../../../../../Common/Components/CustomButton';
import {
  previewDraftAssessmentCheckAction,
  removeDraftQuestionAction
} from '../../../../../redux/previewAssessment/actions';

const RemoveQuestionModal = ({ show, onClose, questionId, regenerateHandler }) => {
  const dispatch = useDispatch();
  const { search } = useLocation();
  const assessmentId = new URLSearchParams(search).get('assessmentId');
  const [innerShow, setInnerShow] = useState(show);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation(['assmCustomization']);

  useEffect(() => {
    if (show) {
      setInnerShow(true);
    } else {
      setTimeout(() => {
        setInnerShow(false);
      }, 300);
    }
  }, [show]);

  async function removeQuestion() {
    setLoading(true);
    const data = {
      questionId: questionId,
      RootAssessmentId: assessmentId
    };
    regenerateHandler();
    const resp = await dispatch(removeDraftQuestionAction(data));

    if (resp?.meta?.requestStatus === 'fulfilled') {
      await dispatch(previewDraftAssessmentCheckAction({ RootAssessmentId: assessmentId }));
      onClose();
    }
    setLoading(false);
  }

  const ModalContent = (
    <div className={styles.RemoveModalWrapper}>
      <div className={styles.RemoveModalTitleRow}>
        <span>{t('removeQ')}</span>
        <CloseButton onClick={onClose} width={24} height={24} />
      </div>
      <div className={styles.RemoveModalBody}>
        {t('qRemoveConfirm')}
      </div>
      <div className={styles.RemoveModalBottomRow}>
        <CustomButton
          customStyle={{ fontSize: '16px' }}
          textField={t('cancel')}
          type={2}
          size="medium"
          buttonOnClick={onClose}
        />
        <CustomButton
          loading={loading}
          customStyle={{ fontSize: '16px' }}
          textField={t('remove')}
          type={6}
          size="medium"
          buttonOnClick={() => removeQuestion()}
        />
      </div>
    </div>
  );

  const WebModalWrapper = (
    <Modal
      modalId={`removeQuestion-${questionId}`}
      show={show}
      onClose={onClose}
      outsideClickClose
      centerHorizontally
      centerVertically
      size={{ width: '600px', height: 'fit-content' }}
      contentStyle={{ borderRadius: '16px' }}
    >
      {ModalContent}
    </Modal>
  );

  return innerShow ? WebModalWrapper : null;
};

export default RemoveQuestionModal;
