export const authSelector = state => state.auth;
export const userSelector = state => state.auth.user;
export const companyInfo = state => state.auth.companyInfo;
export const teamRedux = state => state.auth.team;
export const errorInTeamRedux = state => state.auth.errorInTeam;
export const errorMessageInTeamRedux = state => state.auth.errorMessageInTeam;

export const successInTeamRedux = state => state.auth.successInTeam;
export const successMessageInTeamRedux = state => state.auth.successMessageInTeam;

export const settingsPageErrorMessage = state => state.auth.settingsPageErrorMessage;
export const settingsPageErrorShow = state => state.auth.settingsPageErrorShow;

export const settingsPageSuccessMessage = state => state.auth.settingsPageSuccessMessage;
export const settingsPageSuccessShow = state => state.auth.settingsPageSuccessShow;
export const loginPageErrorFlagRedux = state => state.auth.loginPageErrorFlag;
export const loginPageErrorMessageRedux = state => state.auth.loginPageErrorMessage;
export const upgradePricesRedux = state => state.auth.upgradePrices;


