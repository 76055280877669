import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { useTranslation, Trans } from 'react-i18next';

import CustomButton from '../../../../Common/Components/CustomButton';
import Tooltip from '../../../../Common/Components/Tooltip';
import UnlabeledSingleSelectDropdown from '../../../../Common/Components/UnlabeledSingleSelectDropdown/UnlabeledSingleSelectDropdown';
import { defaultAssessmentFunnelListSelector } from '../../../../redux/candidateDetail/selectors';

import shield from './shield.svg';
import mailIcon from './mailIcon.svg';
import styles from './Positions.module.css';
import TextOverflow from '../../../../Common/Components/TextOverflow';
import {
  setCurrentAssessmentInfo,
  setIsAutoApplyCandidate,
  setShowChangeFunnelPopup,
  setShowRejectionPopupFlag
} from '../../../../redux/candidateDetail/slice';
import { moveCandidateToFunnel, setRejectedOnDropdown, sortOnPriority } from '../helpers';
import {
  dateFormatter,
  getParam,
  urlDynamicQueryUpdateLite,
  useWindowSize
} from '../../../../utils/helpers';
import InfoBanner from '../../../../Common/Components/InfoBanner';

import tick from '../../../../images/Common/successSubmit.svg';
import triggerAssessmentMail from '../../../../Assessment/AssessmentDetail/utils/triggerAssessmentMail';

const PositionCard = ({ item }) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { rejectionDate, rejectionReason } = item;

  // Funnel and position related methods
  // #region
  const defaultAssessmentFunnelListRedux = useSelector(defaultAssessmentFunnelListSelector);

  const [funnelList, setFunnelList] = useState();
  const [selectedValue, setSelectedValue] = useState();
  const [initFlag, setInitFlag] = useState(true);

  /* Sets default selected value for dropdown after each time position list is refreshed */
  function setSelectedValueForDropdown() {
    if (item.currentFunnel === 'Rejected') {
      const rejectedFunnel = defaultAssessmentFunnelListRedux.filter(
        e => e.funnelName === item.currentFunnel
      )[0];
      setSelectedValue({ value: rejectedFunnel?.funnelId, label: rejectedFunnel?.funnelName });
      setInitFlag(false);
    } else {
      setSelectedValue(funnelList?.filter(e => e.label === item.currentFunnel)[0]);
      setInitFlag(true);
    }
  }

  /* Sets funnel list for 'Current Position' assessment */
  useEffect(() => {
    if (defaultAssessmentFunnelListRedux && defaultAssessmentFunnelListRedux.length > 0) {
      setInitFlag(true);
      let temp = [...defaultAssessmentFunnelListRedux].sort(sortOnPriority);
      const isScored = item.score !== -1;
      temp = temp.filter(e =>
        isScored
          ? e.funnelName !== 'Not Scored' && e.funnelName !== 'Rejected'
          : e.funnelName !== 'Scored' && e.funnelName !== 'Rejected'
      );
      const funnelListForDropdown = [];
      temp.forEach(e => funnelListForDropdown.push({ value: e.funnelId, label: e.funnelName }));
      setFunnelList(funnelListForDropdown);
    }
  }, [defaultAssessmentFunnelListRedux]);

  useEffect(() => {
    if (funnelList) {
      setSelectedValueForDropdown();
    }
  }, [funnelList]);

  /* Funnel update for default selected assessment on dropdown click */
  function moveCandidateToFunnelInner() {
    moveCandidateToFunnel(dispatch, id, selectedValue, true, t);
  }

  useEffect(async () => {
    if (selectedValue && selectedValue.label !== 'Rejected') {
      if (!initFlag) {
        // To prevent api call on initial loading
        moveCandidateToFunnelInner();
      } else {
        setInitFlag(false);
      }
    }
  }, [selectedValue]);

  useEffect(() => {
    setSelectedValueForDropdown();
  }, [item.currentFunnel]);

  const [isSelected, setIsSelected] = useState(false);

  function updateStatesOnAssessmentChange() {
    const currentAssessId = getParam(location.search, 'currentAssessmentId');

    if (currentAssessId === item?.companyAssessmentId) {
      setIsSelected(true);
    } else {
      setIsSelected(false);
    }
  }

  function setRejectedOnDropdownInner() {
    const rejectedFunnel = defaultAssessmentFunnelListRedux.filter(
      e => e.funnelName === 'Rejected'
    )[0];
    setRejectedOnDropdown(setSelectedValue, rejectedFunnel);
  }

  // #endregion

  // Event listeners
  useEffect(() => {
    updateStatesOnAssessmentChange();
    window.addEventListener('locationchange', updateStatesOnAssessmentChange);
    window.addEventListener('setRejectedOnDropdownEvent', setRejectedOnDropdownInner);

    return () => {
      window.removeEventListener('locationchange', updateStatesOnAssessmentChange);
      window.removeEventListener('setRejectedOnDropdownEvent', setRejectedOnDropdownInner);
    };
  }, []);

  /* Width calculations for textoverflow */
  // #region
  const [screenW] = useWindowSize();

  /* Color, percentage, date calculations below */
  // #region
  const [color, setColor] = useState();
  const [percentage, setPercentage] = useState();

  useEffect(() => {
    if (item) {
      if (item.allScoreAverage !== null) {
        if (item.score === -1) {
          setColor('#FF7E62');
        } else if (item.score >= item.allScoreAverage) {
          setColor('#4AC389');
        } else if (item.score >= item.allScoreAverage * 0.8) {
          setColor('#FFD600');
        } else {
          setColor('#CA1515');
        }
      } else {
        setColor('#FFD600');
      }

      if (!item.allScoreAverage) {
        setPercentage(100);
      } else {
        setPercentage(
          Math.round(
            (Math.abs(Math.floor(item.allScoreAverage) - item.score) /
              Math.floor(item.allScoreAverage)) *
              100
          )
        );
      }
    }
  }, [item]);
  // #endregion

  const showSendAssessmentFlag = item?.arriveType === 110 && item?.score === -1;
  const [isAssessmentSent, setIsAssessmentSent] = useState(false);
  const [sendButtonLoading, setSendButtonLoading] = useState(false);
  const [bannerStatus, setBannerStatus] = useState(null);

  const sendAssessmentOnNotScored = async () => {
    setSendButtonLoading(true);
    const resp = await triggerAssessmentMail([id], item?.companyAssessmentId, setSendButtonLoading);

    if (resp?.status === 200) {
      setBannerStatus(true);
      setIsAssessmentSent(true);
    } else {
      setBannerStatus(false);
    }
  };

  const isCoensioCandidateFlagForProfile = isSelected && item?.arriveType === 110;
  useEffect(() => {
    dispatch(setIsAutoApplyCandidate(isCoensioCandidateFlagForProfile));
  }, [isCoensioCandidateFlagForProfile]);

  const { t } = useTranslation(['candidateDetail', 'dateDict']);

  const dateText = dateFormatter(item?.addDate, true, x => t(`dateDict:${x}`));

  const isMobile = screenW <= 960;

  const wrapperStyle = isMobile ? { width: '190px' } : { width: '242px' };

  return (
    <>
      <div
        className={styles.PositionCardWrapper}
        style={!isSelected ? { cursor: 'pointer' } : { border: '1px solid #7458D3' }}
        onClick={() => {
          if (getParam(location.search, 'currentAssessmentId') !== item?.companyAssessmentId) {
            urlDynamicQueryUpdateLite('currentAssessmentId', item?.companyAssessmentId);
          }
        }}
        id={`positionCardWrapper-${item.companyAssessmentId}`}
      >
        <div className={styles.UpperPart} id={`positionUpperPart-${item.companyAssessmentId}`}>
          <div className={styles.PositionNameRow}>
            <TextOverflow text={item?.reservedName} className={styles.PositionName} />
            {item?.isSuspect && (
              <div className={styles.isSuspectOverlay} id="isSuspectLabel">
                <Tooltip
                  style={{ color: '#DE4747', maxWidth: '300px' }}
                  content={t('solvedAssmMoreTooltip')}
                >
                  <img id="shield-img" alt="shield" src={shield} className={styles.shield} />
                </Tooltip>
              </div>
            )}
            {item?.companyAssessmentId === getParam(location.search, 'defaultAssessmentId') && (
              <div className={styles.CurrentPositionDiv} id="currentPositionLabel">
                {t('currentPosition').toLocaleUpperCase('tr')}
              </div>
            )}
          </div>

          {showSendAssessmentFlag && !isAssessmentSent ? (
            <div className="w-100">
              <CustomButton
                textField={t('sendAssm')}
                size="medium"
                buttonOnClick={sendAssessmentOnNotScored}
                loading={sendButtonLoading}
                customStyle={{ width: '100%', marginTop: '10px' }}
              />
            </div>
          ) : showSendAssessmentFlag ? (
            <div className="FlexColumn gap-10">
              <div className="FlexRow gap-6 verticallyCenter">
                <img src={tick} alt="success" width={12} height={12} />
                <span className="fw-400 font-15" style={{ color: '#4AC389', lineHeight: '20px' }}>
                  {t('assessmentSent')}
                </span>
              </div>
              <span className="fw-400 font-15 c-darkGray-05">{t('notTakeAssm')}</span>
            </div>
          ) : (
            <div className={styles.ScoreWrapper} style={{ borderColor: color }}>
              <div className={styles.ScoreRow}>
                {/* <div className={styles.ScoreCircle} style={{ background: color }} /> */}
                <text>{item?.score !== -1 ? `${item.score}/100` : 'N/A'}</text>
              </div>

              <text style={{ color: 'rgba(57, 57, 57, 0.5)' }}>
                {item.allScoreAverage === null ? (
                  <text>{t('indAvgNotAvailable')}</text>
                ) : item.score !== -1 ? (
                  item.score >= item.allScoreAverage ? (
                    <>
                      <Trans
                        i18nKey="betterThan"
                        ns="candidateDetail"
                        values={{ percentage: percentage }}
                      >
                        <span>1</span>
                        <span>2</span>
                        <span style={{ color: color }}>3</span>
                      </Trans>
                    </>
                  ) : item.score >= item.allScoreAverage * 0.8 ? (
                    <>
                      <span>
                        <Trans i18nKey="indAvg" ns="candidateDetail">
                          <span>1</span>
                          <span>2</span>
                          <span style={{ color: color }}>3</span>
                        </Trans>
                      </span>
                    </>
                  ) : (
                    <>
                      <Trans
                        i18nKey="worseThan"
                        ns="candidateDetail"
                        values={{ percentage: percentage }}
                      >
                        <span>1</span>
                        <span>2</span>
                        <span style={{ color: color }}>3</span>
                      </Trans>
                    </>
                  )
                ) : (
                  <>
                    <span>
                      <Trans i18nKey="notTakeAssm" ns="candidateDetail">
                        <span>1</span>
                        <span>2</span>
                        <span style={{ color: color }}>3</span>
                      </Trans>
                    </span>
                  </>
                )}
              </text>
            </div>
          )}
        </div>

        <div className={styles.MiddlePart}>
          {isSelected &&
          getParam(location.search, 'defaultAssessmentId') ===
            getParam(location.search, 'currentAssessmentId') ? (
            <>
              <UnlabeledSingleSelectDropdown
                list={funnelList}
                selectedValue={selectedValue}
                setSelectedValue={setSelectedValue}
                placeholder={t('stages')}
                disableDefaultOnClick={screenW <= 960}
                wrapperStyle={wrapperStyle}
                optionalOnClick={() => {
                  if (screenW <= 960) {
                    dispatch(setShowChangeFunnelPopup(true));
                    dispatch(setCurrentAssessmentInfo(item));
                  }
                }}
              />
              {selectedValue?.label === 'Rejected' ? (
                <CustomButton
                  buttonId="positionRejectButton"
                  type={5}
                  textField={t('reactivateButton')}
                  size="small"
                  style={{
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    marginLeft: '18px',
                    height: '42px'
                  }}
                  buttonOnClick={() =>
                    moveCandidateToFunnel(
                      dispatch,
                      id,
                      funnelList.filter(funnel =>
                        item.score === -1
                          ? funnel.label === 'Not Scored'
                          : funnel.label === 'Scored'
                      )[0],
                      true,
                      t
                    )
                  }
                />
              ) : (
                <CustomButton
                  buttonId="positionRejectButton"
                  type={6}
                  textField={t('rejectButton')}
                  size="small"
                  style={{ marginLeft: '18px', height: '42px' }}
                  buttonOnClick={() => dispatch(setShowRejectionPopupFlag(true))}
                />
              )}
            </>
          ) : (
            <text className={styles.FunnelStatusText}>
              <text id="statusText">{t('status')}</text>
              <TextOverflow text={item?.currentFunnel} style={{ color: '#393939' }} />
              {/* <text style={{ color: '#393939' }}>{item.currentFunnel}</text> */}
            </text>
          )}
        </div>

        <div className={styles.LowerPart}>
          {item?.arriveType === 10 ? (
            <div style={{ display: 'flex' }}>
              <img src={mailIcon} alt="mailIcon" style={{ marginRight: '10px' }} />
              {/* Invited on {dateFormatter(item.addDate, true)} */}
              <span>
                {dateText === 'Today' || dateText === 'Bugün' ? (
                  <Trans i18nKey="invitedToday" ns="candidateDetail">
                    <span>1</span>
                    <span>2</span>
                    <span>3</span>
                  </Trans>
                ) : dateText === 'Yesterday' || dateText === 'Dün' ? (
                  <Trans i18nKey="invitedYesterday" ns="candidateDetail">
                    <span>1</span>
                    <span>2</span>
                    <span>3</span>
                  </Trans>
                ) : (
                  <Trans
                    i18nKey="invitedOn"
                    ns="candidateDetail"
                    values={{
                      invitedDate: dateFormatter(item.addDate, true, x => t(`dateDict:${x}`))
                    }}
                  >
                    <span>1</span>
                    <span>2</span>
                  </Trans>
                )}
              </span>
            </div>
          ) : rejectionDate ? (
            dateText === 'Today' || dateText === 'Bugün' ? (
              <Trans i18nKey="rejectToday" ns="candidateDetail">
                <span>1</span>
                <span>2</span>
                <span>3</span>
              </Trans>
            ) : dateText === 'Yesterday' || dateText === 'Dün' ? (
              <Trans i18nKey="appliedYesterday" ns="candidateDetail">
                <span>1</span>
                <span>2</span>
                <span>3</span>
              </Trans>
            ) : (
              <span>
                <Trans
                  i18nKey="rejectedOn"
                  ns="candidateDetail"
                  values={{
                    rejectedDate: dateFormatter(item.addDate, true, x => t(`dateDict:${x}`))
                  }}
                >
                  <span>1</span>
                  <span>2</span>
                </Trans>
              </span>
            )
          ) : (
            <span>
              {dateText === 'Today' || dateText === 'Bugün' ? (
                <Trans i18nKey="appliedToday" ns="candidateDetail">
                  <span>1</span>
                  <span>2</span>
                  <span>3</span>
                </Trans>
              ) : dateText === 'Yesterday' || dateText === 'Dün' ? (
                <Trans i18nKey="appliedYesterday" ns="candidateDetail">
                  <span>1</span>
                  <span>2</span>
                  <span>3</span>
                </Trans>
              ) : (
                <Trans
                  i18nKey="appliedOn"
                  ns="candidateDetail"
                  values={{
                    appliedDate: dateFormatter(item.addDate, true, x => t(`dateDict:${x}`))
                  }}
                >
                  <span>1</span>
                  <span>2</span>
                </Trans>
              )}
            </span>
          )}

          {rejectionReason && <div className={styles.rejectionReason}>{rejectionReason}</div>}
        </div>
      </div>

      <InfoBanner
        show={bannerStatus !== null}
        errorFlag={bannerStatus === false}
        text={bannerStatus ? t('assmMailSentBanner') : t('errorOccurredBanner')}
        setStatus={() => setBannerStatus(null)}
      />
    </>
  );
};
export default PositionCard;
