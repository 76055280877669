import client from '../../constant/client';
import API from '../../constant/apiVol2';

export const getCurrentPlanService = data =>
  new Promise((resolve, reject) => {
    client.get(API.getCurrentPlan, { params: data }).then(resolve).catch(reject);
  });

export const getAllPlansService = data =>
  new Promise((resolve, reject) => {
    client.get(API.getAllPlans, { params: data }).then(resolve).catch(reject);
  });

export const getCurrentUsageService = data =>
  new Promise((resolve, reject) => {
    client.get(API.getCurrentUsage, { params: data }).then(resolve).catch(reject);
  });

export const getAddOnsService = data =>
  new Promise((resolve, reject) => {
    client.get(API.getAddOns, { params: data }).then(resolve).catch(reject);
  });

export const getPackageDetailService = data =>
  new Promise((resolve, reject) => {
    client.get(API.getPackageDetail, { params: data }).then(resolve).catch(reject);
  });

export const getBillingInfoService = data =>
  new Promise((resolve, reject) => {
    client.get(API.getBillingInfo, { params: data }).then(resolve).catch(reject);
  });

export const getCountriesService = data =>
  new Promise((resolve, reject) => {
    client.get(API.getCountries, { params: data }).then(resolve).catch(reject);
  });
// Card related apis
export const createCardService = data =>
  new Promise((resolve, reject) => {
    client.post(API.addCard, data).then(resolve).catch(reject);
  });
export const getMyCardsService = data =>
  new Promise((resolve, reject) => {
    client.get(API.getMyCards, { params: data }).then(resolve).catch(reject);
  });

export const contactSalesService = data =>
  new Promise((resolve, reject) => {
    client.post(API.contactSales, data).then(resolve).catch(reject);
  });

export const createPackageOrderService = data =>
  new Promise((resolve, reject) => {
    client.post(API.createPackageOrder, data).then(resolve).catch(reject);
  });
export const comparePlansAndFeaturesService = data =>
  new Promise((resolve, reject) => {
    client.get(API.comparePlansAndFeatures, data).then(resolve).catch(reject);
  });

export const editBillingInformationService = data =>
  new Promise((resolve, reject) => {
    client.post(API.editBillingInfo, data).then(resolve).catch(reject);
  });

export const unsubscribeAddOnService = data =>
  new Promise((resolve, reject) => {
    client.post(API.unsubscribeAddOn, data).then(resolve).catch(reject);
  });
export const unsubscribePlanService = data =>
  new Promise((resolve, reject) => {
    client.post(API.unsubscribePlan, data).then(resolve).catch(reject);
  });

export const downgradePackageService = data =>
  new Promise((resolve, reject) => {
    client.post(API.downgradePackage, data).then(resolve).catch(reject);
  });
export const upgradePackageService = data =>
  new Promise((resolve, reject) => {
    client.post(API.upgradePackage, data).then(resolve).catch(reject);
  });
export const getProfileService = data =>
  new Promise((resolve, reject) => {
    client.get(API.getProfile, { params: data }).then(resolve).catch(reject);
  });

export const updateMyProfileService = data =>
  new Promise((resolve, reject) => {
    client.post(API.updateProfile, data).then(resolve).catch(reject);
  });

export const signupRequestService = data =>
  new Promise((resolve, reject) => {
    client.post(API.signupRequest, data).then(resolve).catch(reject);
  });

export const abortCancelInProgressService = data =>
  new Promise((resolve, reject) => {
    client.post(API.abortCancelInProgress, data).then(resolve).catch(reject);
  });

export const purchaseAddOnService = data =>
  new Promise((resolve, reject) => {
    client.post(API.purchaseAddOn, data).then(resolve).catch(reject);
  });

export const isAuthorizedForCandidateReportService = data =>
  new Promise((resolve, reject) => {
    client.post(API.isAuthorizedForCandidateReport, data).then(resolve).catch(reject);
  });

export const unlockCandidateReportService = data =>
  new Promise((resolve, reject) => {
    client.post(API.unlockCandidateReport, data).then(resolve).catch(reject);
  });

export const getInvoiceListService = data =>
  new Promise((resolve, reject) => {
    client.get(API.getInvoiceList, { params: data }).then(resolve).catch(reject);
  });
export const downloadInvoiceService = data =>
  new Promise((resolve, reject) => {
    client
      .get(API.downloadInvoice, { params: data }, { responseType: 'blob' })
      .then(resolve)
      .catch(reject);
  });
