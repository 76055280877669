import axios from 'axios';
import config from './configVol2';

const candidate = axios.create({
  baseURL: config.BASE_URL
});

candidate.interceptors.request.use(
  config => {
    const assessmentSessionToken = localStorage.getItem('assessmentSessionToken');

    if (assessmentSessionToken) {
      config.headers.Authorization = `Bearer ${assessmentSessionToken}`;
    }

    config.headers['access-control-allow-origin'] = '*';
    config.headers['access-control-allow-methods'] = '*';
    config.headers['access-control-allow-headers'] =
      'Origin, X-Requested-With, Content-Type, Accept, Authorization';
    return config;
  },
  error => Promise.reject(error?.response?.data)
);

candidate.interceptors.response.use(
  response => response,
  error => {
    if (error.response) {
      return Promise.reject(error.response?.data);
    }
    return Promise.reject(error);
  }
);

export default candidate;
