import React from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Modal from '../../Common/CustomModal/Modal';
import CloseButton from '../../Common/Components/CloseButton';

import styles from '../Styles/NotAuthorizedPopup.module.css';

const NotAuthorizedPopup = ({ show }) => {
  const history = useHistory();
  const { t } = useTranslation(['billingTab'])
  return (
    <Modal
      className={styles.modalContent}
      outsideClickClose
      centerHorizontally
      centerVertically
      show={show}
      onClose={() => history.goBack()}
      size={{ width: '572px', height: '332px' }}
      contentStyle={{
        background: '#FFFFFF',
        borderRadius: '16px',
        padding: '23px 28px'
      }}
      backdropStyle={{ overflow: 'hidden' }}
    >
      <div className={styles.container}>
        <CloseButton className={styles.close} onClick={() => history.goBack()} />
        <span className={styles.warningText}>{t('notAuth')}</span>
      </div>
    </Modal>
  );
};

export default NotAuthorizedPopup;
