import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import RadioButton from '../../../../Common/Components/RadioButtonItem';
import styles from './AdditionalScreeningCard.module.css';

const AdditionalSingleSelect = ({ question, handleSingleSelect, previousResponse }) => {
  const [selected, setSelected] = useState(null);

  useEffect(() => {
    handleSingleSelect(question.questionId, selected);
  }, [selected]);

  useEffect(() => {
    if (previousResponse !== null) {
      setSelected(question.options[previousResponse]);
    }
  }, [previousResponse]);

  return (
    <div className={styles.questionWrapper}>
      <div className={styles.question}>
        {question.title} {question.isMandatory && <span style={{ color: 'red' }}>*</span>}
      </div>
      <div className={styles.wrapper}>
        {question.options?.map(e => (
          <SingleSelectRadioButton
            key={e.id}
            id={e}
            item={e}
            selected={selected === e}
            setSelected={setSelected}
            isRequired={question.isMandatory}
          />
        ))}
      </div>
    </div>
  );
};

const SingleSelectRadioButton = ({ item, id, selected, setSelected, isRequired }) => {
  return (
    <div className={styles.checkboxWrapper}>
      <RadioButton
        checkboxBoxStyle={{ border: '0.9px solid #585858' }}
        isActive={selected}
        activate={setSelected}
        item={item}
        disableUnselect={isRequired}
        myKey={id}
      />
    </div>
  );
};

SingleSelectRadioButton.propTypes = {
  id: PropTypes.number.isRequired,
  selected: PropTypes.bool.isRequired,
  setSelected: PropTypes.func.isRequired
};

export default AdditionalSingleSelect;
