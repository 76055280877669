import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import MultiSelectAnswers from '../Components/multiSelectAnswers';
import QuestionDetails from '../Components/questionDetails';
import styles from '../Styles/MultiSelect.module.css';
import CloseButton from '../../../Common/Components/CloseButton';
import { createMultipleChoiceQuestionAction2 } from '../../../redux/addQuestion/actions';
import { assessmentLabelRedux, categoryRedux, difficultyRedux, isSuccessRedux, languageRedux, limitRedux, multiSelectAnswersRedux, questionDescriptionRedux, selectedOptionIndexRedux, statusRedux, subcategoryRedux, titleRedux } from '../../../redux/addQuestion/selectors';
import { setInitial } from '../../../redux/addQuestion/slice';
import InformationPopup from '../Modals/InformationPopup';
import QuestionDescTextEditor from '../Components/QuestionDescTextEditor';
import Header from '../../../Common/Components/Header';

function MultiSelectQuestionPage() {
  const dispatch = useDispatch();

  // Selectors
  const reduxCategory = useSelector(categoryRedux);
  const reduxAssessmentLabel = useSelector(assessmentLabelRedux);
  const reduxSubcategory = useSelector(subcategoryRedux);
  const reduxTitle = useSelector(titleRedux);
  const reduxDifficulty = useSelector(difficultyRedux);
  const reduxLanguage = useSelector(languageRedux);
  const reduxLimit = useSelector(limitRedux);
  const reduxStatus = useSelector(statusRedux);

  const reduxQuestionDescription = useSelector(questionDescriptionRedux);

  const reduxIsSuccess = useSelector(isSuccessRedux);

  const reduxMultiSelectAnswers = useSelector(multiSelectAnswersRedux);
  const reduxSelectedOptionIndex = useSelector(selectedOptionIndexRedux);


  // We need functions for next requests: question stage implies the stage of the question screen (0: Function signature, 1: Test cases, 2: Verification code, 3: Question details)
  const [questionStage, setQuestionStage] = useState(1);
  // const [htmlContent, setHtmlContent] = useState('');
  const [isInQuestionDetail, setInQuestionDetail] = useState(false);

  const [saveStatus, setSaveStatus] = useState(0);
  const [showPopup, setShowPopup] = useState(false);


  function checkSaveStatus() {
    let bool = true;
    if (reduxMultiSelectAnswers) {
      reduxMultiSelectAnswers.forEach(element => {
        if (element === '') {
          bool = false;
        }
      });
    }else{
      bool = false;
    }
    if (
      !reduxCategory || reduxCategory === "" ||
      !reduxAssessmentLabel || reduxAssessmentLabel === "" ||
      !reduxSubcategory || reduxSubcategory === "" ||
      !reduxTitle || reduxTitle === "" ||
      !reduxDifficulty || reduxDifficulty === "" ||
      !reduxLanguage || reduxLanguage === "" ||
      !reduxQuestionDescription ||
      reduxQuestionDescription === '' ||
      reduxQuestionDescription.trim() === '<p></p>'
    ) {
      bool = false;
    }
    return bool;
  }

  useEffect(async () => {
    checkSaveStatus();
    setSaveStatus(0);
  }, [saveStatus]);



  

  const createQuestion = async () => {
    let questionBody = reduxQuestionDescription;
    questionBody = questionBody.replaceAll('"', '\\"');
    const questionBodyArray = questionBody.split('<p>');
    let updatedQuestionBody = '"';
    questionBodyArray.forEach((element, index) => {
      if (index === questionBodyArray.length - 1) {
        updatedQuestionBody = updatedQuestionBody.concat(element.trimEnd(" "));
      } else {
        updatedQuestionBody = updatedQuestionBody.concat(element);
        updatedQuestionBody = updatedQuestionBody.concat(
          '<p class=\\"text-dark-75 font-size-xlg font-weight-normal pt-5 mb-1\\">'
        );
      }
    });
    updatedQuestionBody = updatedQuestionBody.concat('"');
    updatedQuestionBody = updatedQuestionBody.replaceAll('>\n<', '><');

    const rawData = {
      Title: reduxTitle,
      Time: reduxLimit,  // parselamak gerekebilir !!!
      Category: reduxCategory,
      SubCategory: reduxSubcategory,
      // AssessmentLabel: reduxAssessmentLabel,
      Difficulty: reduxDifficulty,
      Language: reduxLanguage,
      // TODO
      DescHtmlJson: updatedQuestionBody,
      OptionsJson: JSON.stringify(reduxMultiSelectAnswers), // parselamak vs. gerekebilir
      OptionAnswer: reduxSelectedOptionIndex, // parselamak gerekebilir
      IsActive: reduxStatus
    };
    const a = await dispatch(createMultipleChoiceQuestionAction2(rawData));
  };

  // Handles the right panel according to the stage of the question creation stage

  useEffect(async () => {
    if (questionStage === 2) {
      setInQuestionDetail(true);
    } else {
      setInQuestionDetail(false);
    }
  }, [questionStage]);

  function backClicked() {
    if (questionStage !== 1) {
      setQuestionStage(questionStage - 1);
    }
  }

  function nextClicked() {
    setQuestionStage(questionStage + 1);
  }

  function saveClicked() {
    setShowPopup(true);
    createQuestion();
  }

  // Called when the user clicks on the close button
  function closeQuestion(e) {
    e.stopPropagation();
    alert("You're about to leave the page. Your work will be lost !");
    dispatch(setInitial());
    setQuestionStage(1);
  }

  // const getContent = htmlContentProp => {
  //   setHtmlContent(htmlContentProp);
  // };

  const popupOnHide = () => {
    switch (reduxIsSuccess) {
      case true:
        dispatch(setInitial());
        setQuestionStage(1);
        setShowPopup(false);
        break;
      case false:
        setShowPopup(false);
        break;
      default:
        break;
    }
  }

  return (
    <div style={{overflowY: "hidden"}}>
      {/* <QuestionHeader /> */}
      <Header />
      <InformationPopup show={showPopup} onHide={popupOnHide} />
      <div className={styles.QuestionWrapperPane}>
        <div className={styles.QuestionHeaderRow}>
          <div className={styles.QuestionHeader}>Add Multiple Choice Question</div>
          <CloseButton 
            onClick={e => {closeQuestion(e);}}
            size="large"
          />
        </div>
        <div className={styles.QuestionPane}>
          <div className={styles.LeftPane}>
            <div className={styles.height30px}>Question Text:</div>
            <div className={styles.TextEditorQuestionText}>
              <QuestionDescTextEditor />
            </div>
          </div>
          <div className={styles.toolbarBorder} />
          {questionStage === 1 ? <MultiSelectAnswers /> : null}
          {questionStage === 2 ? <QuestionDetails detailChange={setSaveStatus} /> : null}
        </div>

        <div className={styles['nav-question']}>
          <button
            className={styles.questionNavButton}
            type="button"
            onClick={backClicked}
            style={{ gridColumnStart: 2 }}
          >
            BACK
          </button>
          {isInQuestionDetail ? (
            <button
              disabled={!checkSaveStatus()}
              className={styles.questionNavButton}
              type="button"
              onClick={saveClicked}
              style={{ gridColumnStart: 3 }}
            >
              SAVE
            </button>
          ) : (
            <button
              className={styles.questionNavButton}
              type="button"
              onClick={nextClicked}
              style={{ gridColumnStart: 3 }}
            >
              NEXT
            </button>
          )}
        </div>
      </div>
    </div>
  );
}

export default MultiSelectQuestionPage;
