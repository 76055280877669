import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';

import styled from 'styled-components';
import useGetCustomFunnelHandler from './useGetCustomFunnelHandler';
import FunnelGroupItem from '../Components/Settings/PositionTab/FunnelGroupItem';
import FunnelGroupItemSkeletonLoading from '../Components/Settings/PositionTab/FunnelGroupItemSkeletonLoading';
import TextOverflow from '../../Common/Components/TextOverflow';

const COENSIO_PURPLE = 'var(--coensio)';
const COENSIO_WHITE = 'var(--white)';
const COENSIO_BLACK = 'var(--dark-gray)';

export default function useCustomFunnelLeftColumnHandler() {
  const [activeFunnelInformation, setActiveFunnelInformation] = useState();

  const { funnelGroupList, setFunnelGroupList, funnelLoading } = useGetCustomFunnelHandler();

  const { t } = useTranslation(['positionTab']);

  const FunnelGroupList = (
    <FunnelGroupListWrapper
      height={`max(100%, ${(funnelGroupList?.length + 1) * 64 + funnelGroupList?.length * 8}px) `}
    >
      <FunnelGroupItem
        activeSelection={!activeFunnelInformation}
        onClick={() => setActiveFunnelInformation()}
      >
        <PlusIcon fill={activeFunnelInformation ? COENSIO_PURPLE : COENSIO_WHITE} />
        <span style={{ color: activeFunnelInformation ? COENSIO_PURPLE : COENSIO_WHITE }}>
          {t('addFunnel')}
        </span>
      </FunnelGroupItem>

      {!funnelLoading ? (
        funnelGroupList?.map(x => (
          <FunnelGroupItem
            activeSelection={x?.funnelGroupId === activeFunnelInformation?.funnelGroupId}
            onClick={() => setActiveFunnelInformation(x)}
          >
            <TextOverflow
              text={x?.funnelGroupName}
              style={{
                color:
                  x?.funnelGroupId === activeFunnelInformation?.funnelGroupId
                    ? COENSIO_WHITE
                    : COENSIO_BLACK
              }}
            />
          </FunnelGroupItem>
        ))
      ) : (
        <FunnelGroupItemSkeletonLoading />
      )}
    </FunnelGroupListWrapper>
  );

  return {
    FunnelGroupList,
    activeFunnelInformation,
    setFunnelGroupList,
    funnelGroupList,
    setActiveFunnelInformation
  };
}

const PlusIcon = ({ fill = '#7458D3' }) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.24206 13.4297H17.377C17.5101 13.4297 17.619 13.3453 17.619 13.2422V12.1172C17.619 12.0141 17.5101 11.9297 17.377 11.9297H6.24206C6.10893 11.9297 6 12.0141 6 12.1172V13.2422C6 13.3453 6.10893 13.4297 6.24206 13.4297Z"
        fill={fill}
      />
      <path
        d="M11 7.17175L11 18.3067C11 18.4398 11.0844 18.5487 11.1875 18.5487L12.3125 18.5487C12.4156 18.5487 12.5 18.4398 12.5 18.3067L12.5 7.17175C12.5 7.03862 12.4156 6.92969 12.3125 6.92969L11.1875 6.92969C11.0844 6.92969 11 7.03862 11 7.17175Z"
        fill={fill}
      />
    </svg>
  );
};

const FunnelGroupListWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 8px;
  height: 100%;

  overflow-x: hidden;
  overflow-y: auto;
  scrollbar-width: none; /* For Firefox */
  -ms-overflow-style: none; /* For Internet Explorer 10+ */

  /* For Webkit browsers like Chrome and Safari */
  &::-webkit-scrollbar {
    visibility: hidden;
  }

  &::-webkit-scrollbar-thumb {
    visibility: hidden;
  }

  &:hover {
    scrollbar-width: auto; /* Show scrollbar on hover for Firefox */
    -ms-overflow-style: auto; /* Show scrollbar on hover for Internet Explorer 10+ */

    &::-webkit-scrollbar {
      visibility: visible;
    }

    &::-webkit-scrollbar-thumb {
      visibility: visible;
    }
  }

  &::after {
    content: '';
    position: absolute;
    right: 10px; // Distance from the right edge
    top: 0;
    bottom: 0;
    width: 1px; // Thickness of the 'border'
    background: rgba(244, 244, 244, 1);
    height: ${props => props.height};
  }
`;
