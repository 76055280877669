import client from '../../constant/client';
import API from '../../constant/apiVol2';
import candidate from '../../constant/candidate';

export const applicantAssessmentDetailWithEmailService = data =>
  new Promise((resolve, reject) => {
    client.post(API.applicantAssessmentApplyEncrypted, data).then(resolve).catch(reject);
  });

export const applicantAssessmentDetailService = data =>
  new Promise((resolve, reject) => {
    client.get(API.getCandidateApplicationDetail, { params: data }).then(resolve).catch(reject);
  });
export const candidateHasDataService = data =>
  new Promise((resolve, reject) => {
    client.get(API.candidateHasData, { params: data }).then(resolve).catch(reject);
  });
export const candidateContractCheckService = data =>
  new Promise((resolve, reject) => {
    client.post(API.candidateContractCheck, data).then(resolve).catch(reject);
  });

export const candidateContractConfirmService = data =>
  new Promise((resolve, reject) => {
    client.post(API.candidateContractConfirm, data).then(resolve).catch(reject);
  });

export const startAssessmentSessionService = data =>
  new Promise((resolve, reject) => {
    client.post(API.startAssessmentSession, data).then(resolve).catch(reject);
  });

export const getNextQuestionService = data =>
  new Promise((resolve, reject) => {
    candidate.get(API.getNextQuestion, { params: data }).then(resolve).catch(reject);
  });

export const getCurrentQuestionService = data =>
  new Promise((resolve, reject) => {
    candidate.get(API.getCurrentQuestion, { params: data }).then(resolve).catch(reject);
  });

export const submitMultipleChoiceQuestionAnswerService = data =>
  new Promise((resolve, reject) => {
    candidate.post(API.submitMultipleChoiceQuestionAnswer, data).then(resolve).catch(reject);
  });

export const submitMultiCorrectAnswerService = data =>
  new Promise((resolve, reject) => {
    candidate.post(API.submitMultipleCorrectQuestionAnswer, data).then(resolve).catch(reject);
  });

export const submitFreeTextQuestionAnswerService = data =>
  new Promise((resolve, reject) => {
    candidate.post(API.submitFreeTextQuestionAnswer, data).then(resolve).catch(reject);
  });

export const submitCodingQuestionAnswerService = data =>
  new Promise((resolve, reject) => {
    candidate.post(API.submitCodingQuestionAnswer, data).then(resolve).catch(reject);
  });

export const runCodeTestPageService = data =>
  new Promise((resolve, reject) => {
    client.post(API.runCodeTestPage, data).then(resolve).catch(reject);
  });
export const ChangeOrAddApplicantDetailService = data =>
  new Promise((resolve, reject) => {
    candidate.post(API.ChangeOrAddApplicantDetail, data).then(resolve).catch(reject);
  });
export const incrementTabSwitchService = data =>
  new Promise((resolve, reject) => {
    candidate.post(API.incrementTabSwitch, data).then(resolve).catch(reject);
  });
export const applyCandidateToAssessmentService = data =>
  new Promise((resolve, reject) => {
    client.post(API.applyCandidateToAssessment, data).then(resolve).catch(reject);
  });

export const getAssessmentSessionDetailService = data =>
  new Promise((resolve, reject) => {
    candidate.get(API.getAssessmentSessionDetail, { params: data }).then(resolve).catch(reject);
  });

export const getCandidateInfoFromEmailService = data =>
  new Promise((resolve, reject) => {
    client.get(API.getCandidateInfoFromEmail, { params: data }).then(resolve).catch(reject);
  });

export const getScreeningQuestionsService = data =>
  new Promise((resolve, reject) => {
    client.get(API.getScreeningQuestions, { params: data }).then(resolve).catch(reject);
  });

export const saveScreeningAnswerService = data =>
  new Promise((resolve, reject) => {
    candidate.post(API.saveScreeningAnswer, data).then(resolve).catch(reject);
  });
