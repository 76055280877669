import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useTranslation, Trans } from 'react-i18next';
import VerificationInput from 'react-verification-input';
import {
  CandidateEmail,
  AssessmentTitle,
  CompanyName,
  LogoPath
} from '../../../../redux/AssessmentSession/selectors';
import CustomButton from '../../../../Common/Components/CustomButton';
import { validateVerificationCodeAction, sendEmailVerificationCodeAction } from '../../Api/actions';
import mockImage2 from '../../../../images/Common/Coensio_newLogo.svg';
import { ASSESSMENT_FLOW_MOBILE_THRESHOLD, useWindowSize } from '../../../../utils/helpers';
import styles from './MailOTPVerification.module.css';

const MailOTPVerification = ({ application, setIsVerified }) => {
  const location = useLocation();
  const [width] = useWindowSize();

  const email = useSelector(CandidateEmail);
  const reduxAssessmentName = useSelector(AssessmentTitle);
  const reduxLogoPath = useSelector(LogoPath);
  const [innerPath, setInnerPath] = useState(reduxLogoPath);
  const companyName = useSelector(CompanyName);
  const assessmentId = application
    ? location.pathname.split('/')[2]
    : new URLSearchParams(location.search).get('assessment');

  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState();
  const [timer, setTimer] = useState();
  const [verificationCode, setVerificationCode] = useState('');
  const [codeError, setCodeError] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);

  const { t } = useTranslation(['otpVerification']);

  useEffect(() => {
    if (email) {
      checkTimer();
    }
  }, [email]);

  useEffect(() => {
    setCodeError(false);
  }, [verificationCode]);

  useEffect(() => {
    setVerificationCode('');
  }, [step]);

  const fetchMailVerificationCode = async () => {
    setLoading(true);
    setCodeError(false);
    setVerificationCode('');

    await sendEmailVerificationCodeAction({
      email,
      verificationContext: 2,
      companyAssessmentId: assessmentId
    })
      .then(res => {
        if (res.status === 200 && res.data) {
          setStep(1);
          setTimer(120);
        } else if (res.message) {
          setErrorMessage(res.message);
          setStep(0);
        } else {
          setErrorMessage('Bir hata oluştu. Lütfen daha sonra tekrar deneyin.');
          setStep(0);
        }
      })
      .catch(() => {
        setLoading(false);
        setErrorMessage(t('errorOccured'));
        setStep(0);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleSendCode = async () => {
    setLoading(true);
    await validateVerificationCodeAction({
      email,
      verificationCode: verificationCode.toUpperCase(),
      verificationType: 2,
      verificationContext: 2,
      companyAssessmentId: assessmentId
    })
      .then(res => {
        if (res.status === 200 && res.data.success) {
          setIsVerified(true);
        } else if (res.message) {
          setCodeError(true);
        }
      })
      .catch(() => {
        setCodeError(true);
      })
      .finally(() => {
        setLoading(false);
        localStorage.removeItem('mailOtpTimer');
      });
  };

  const saveTimerToLocalStorage = () => {
    const expirationTime = new Date().getTime() + timer * 1000;
    localStorage.setItem('mailOtpTimer', expirationTime);
  };

  const checkTimer = () => {
    const savedExpirationTime = localStorage.getItem('mailOtpTimer');
    if (savedExpirationTime) {
      const currentTime = new Date().getTime();
      const remainingTime = Math.floor((savedExpirationTime - currentTime) / 1000);

      if (remainingTime > 0) {
        setTimer(remainingTime);
        setStep(1);
      } else {
        fetchMailVerificationCode();
      }
    } else {
      fetchMailVerificationCode();
    }
  };

  useEffect(() => {
    let interval;
    if (step === 1 && timer > 0) {
      interval = setInterval(() => {
        setTimer(prevTimer => {
          const newTime = prevTimer - 1;
          saveTimerToLocalStorage();
          return newTime;
        });
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [step, timer]);

  return (
    <div className={styles.container}>
      <div className={styles.cardWrapper}>
        <div className={styles.otpBigCard}>
          <div className={styles.otpInnerCard}>
            <div className={styles.otpContainer}>
              {loading ? (
                <span
                  style={{
                    width: '100%',
                    textAlign: 'center'
                  }}
                >
                  {t('loading')}
                </span>
              ) : (
                <>
                  {width <= ASSESSMENT_FLOW_MOBILE_THRESHOLD ? (
                    <img
                      alt=""
                      src={innerPath || mockImage2}
                      style={{ maxHeight: 20, maxWidth: 110, marginBottom: 15 }}
                    />
                  ) : (
                    <span className={styles.otpHeader}>{companyName}</span>
                  )}
                  <span className={styles.otpCompanyName}>{reduxAssessmentName}</span>
                  <div className={styles.inputContainer}>
                    {step === 0 ? (
                      <>
                        {errorMessage && (
                          <span className={styles.otpDescription}>{errorMessage}</span>
                        )}
                        <span className={styles.otpDescription}>{t('tryAgain')}</span>
                      </>
                    ) : step === 1 ? (
                      <>
                        <span className={styles.otpDescription}>
                          <Trans
                            i18nKey="enterCodeMail"
                            ns="otpVerification"
                            values={{ email: email }}
                          >
                            0<span>1</span>
                            <span>2</span>
                            <span>3</span>
                            <span>4</span>
                          </Trans>
                          {/* Başvurunu tamamlamak için {email} adresine gönderdiğimiz doğrulama kodunu
                          gir! */}
                        </span>
                        {timer !== 0 && (
                          <>
                            <span className="">
                              <VerificationInput
                                value={verificationCode.toUpperCase()}
                                length={6}
                                placeholder=""
                                inputProps={{
                                  disabled: timer === 0 || loading
                                }}
                                validChars="A-Za-z0-9çÇğĞıİiöÖşŞüÜ"
                                onChange={e => {
                                  const normalizedValue = e
                                    .replace(/ç|Ç/g, 'c')
                                    .replace(/ğ|Ğ/g, 'g')
                                    .replace(/ı|I|i|İ/g, 'i')
                                    .replace(/ö|Ö/g, 'o')
                                    .replace(/ş|Ş/g, 's')
                                    .replace(/ü|Ü/g, 'u');

                                  setVerificationCode(normalizedValue);
                                }}
                                classNames={{
                                  container: styles.verificationInputContainer,
                                  character: codeError
                                    ? styles.verificationInputCharacterError
                                    : timer !== 0
                                    ? styles.verificationInputCharacter
                                    : styles.verificationInputCharacterDisabled,
                                  characterInactive: styles.verificationInputCharacterInactive,
                                  characterSelected: styles.verificationInputCharacterSelected,
                                  characterFilled: styles.verificationInputCharacterFilled
                                }}
                              />
                              {codeError && (
                                <span className={styles.errorText}>{t('codeError')}</span>
                              )}
                            </span>

                            <div style={{ display: 'flex', alignItems: 'center' }}>
                              <div>{t('newCodeTime')}&nbsp;</div>
                              <span>
                                {Math.floor(timer / 60)}:{timer % 60 < 10 ? '0' : ''}
                                {timer % 60}
                              </span>
                            </div>
                          </>
                        )}
                        {timer !== 0 ? (
                          <CustomButton
                            style={{ width: '100%', marginTop: '2rem' }}
                            buttonOnClick={() => handleSendCode()}
                            loading={loading}
                            type={1}
                            isDisabled={
                              !verificationCode || verificationCode?.length < 6 || timer === 0
                            }
                            buttonType="button"
                            textField={t('verify')}
                          />
                        ) : (
                          <CustomButton
                            style={{ width: '100%', marginTop: '2rem' }}
                            buttonOnClick={() => fetchMailVerificationCode()}
                            loading={loading}
                            type={1}
                            isDisabled={loading}
                            buttonType="button"
                            textField={t('sendNewCode')}
                          />
                        )}
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MailOTPVerification;
