import React, { useEffect, useState } from 'react';
import styles from './AdditionalScreeningCard.module.css';
import TextInput from '../../../../Common/Components/TextInput';

const AdditionalFreeText = ({ question, handleFreeText, previousResponse }) => {
  const [text, setText] = useState([]);

  useEffect(() => {
    handleFreeText(question.questionId, text);
  }, [text]);

  useEffect(() => {
    if (previousResponse) {
      setText(previousResponse);
    }
  }, [previousResponse]);

  return (
    <div className={styles.questionWrapper}>
      <div className={styles.question}>
        {question.title} {question.isMandatory && <span style={{ color: 'red' }}>*</span>}
      </div>
      <div className={styles.wrapper}>
        <TextInput
          placeholder={question.title}
          style={{ width: '100%', height: '100%' }}
          value={text}
          onChange={event => {
            setText(event.target.value);
          }}
        />
      </div>
    </div>
  );
};

export default AdditionalFreeText;
