import React, { useState, useEffect, useRef } from 'react';
import styles from './Modal.module.css';

export default function Modal({
  // IMPORTANT NOTES:
  // Layer 1: backdrop, style is mostly only for background color
  // Layer 2: wrapper, style is for position alignment mosty. Give margin styling to this layer
  // Layer 3: content, style is for the box area of the modal (border, padding etc. should be given here)
  // scrollInside CANNOT be used without a size.height prop
  // centerHorizontally prop centers modal horizontally and looks for position y
  // centerVertically prop centers modal vertically and looks for position x
  // outsideClickClose boolean controls close preference on outside click
  // Avoid using centerVertically for possibly longer modals than 100vh
  modalId,
  modalBodyId,
  backdropRef,
  show,
  onClose = () => {},
  children,
  centerHorizontally = false,
  centerVertically = false,
  backdropStyle = {},
  contentStyle = {},
  bodyStyle = {},
  position = { x: 0, y: 0 },
  size = {},
  scrollInside,
  outsideClickClose = false,
  startFromBottom = false,
  maxHeight, // only for mobile modals
  outsideTouchClose = false,
  // scrollOnTouch // only for mobile modals: for scroll behavior
  overlayClassName,
  bodyClassName,
  contentClassName
}) {
  const ref = useRef();
  const [show2, setShow2] = useState();
  // let counter = 0;

  const [renderFlag, setRenderFlag] = useState();
  const [renderTimeoutId, setRenderTimeoutId] = useState(-1);

  useEffect(() => {
    if (show) {
      setRenderFlag(true);
      if (renderTimeoutId !== -1) {
        clearTimeout(renderTimeoutId);
        setRenderTimeoutId(-1);
      }

      setTimeout(() => {
        setShow2(true);
      }, 100);
    } else {
      setShow2(false);

      setRenderTimeoutId(
        setTimeout(() => {
          setRenderFlag(false);
        }, 1000)
      );
    }
  }, [show]);

  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (
          ref.current &&
          !ref.current.contains(event.target) &&
          (outsideClickClose || outsideTouchClose)
        ) {
          onClose();
        }
      }
      // Bind the event listener
      if (outsideClickClose) {
        document.addEventListener('mousedown', handleClickOutside);
      }
      if (outsideTouchClose) {
        document.addEventListener('touchstart', handleClickOutside);
      }
      return () => {
        // Unbind the event listener on clean up
        if (outsideClickClose) {
          document.removeEventListener('mousedown', handleClickOutside);
        }
        if (outsideTouchClose) {
          document.removeEventListener('touchstart', handleClickOutside);
        }
      };
    }, [ref, outsideClickClose, outsideTouchClose]);
  }

  useOutsideAlerter(ref);

  return renderFlag ? (
    <div
      className={`${styles.mymodal} ${show2 && styles.myshow} ${overlayClassName || ''}`}
      style={backdropStyle}
      ref={backdropRef}
    >
      <div
        id={modalBodyId}
        className={`${startFromBottom ? styles.modalBodyBottom : styles.modalBody} ${
          centerHorizontally ? styles.centerH : ''
        } ${centerVertically ? styles.centerV : ''}
        ${bodyClassName || ''}`}
        style={{
          minHeight: centerVertically ? '100dvh' : 'fit-content',
          padding: centerVertically ? '30px 0px' : 'unset',
          transform:
            show2 && !centerHorizontally && !centerVertically
              ? `translate(${position.x}, ${position.y})`
              : show2 && centerHorizontally
              ? `translateY(${position.y})`
              : show2 && centerVertically
              ? `translateX(${position.x})`
              : '',
          ...bodyStyle
        }}
      >
        <div
          // onTouchStart={() => {
          //   if (scrollOnTouch) {
          //     const element = document.getElementById(modalId);
          //     counter += 1;
          //     if (element?.scrollHeight > element?.offsetHeight) {
          //       element?.classList.add(`${styles.mouseEnter}`);
          //     }
          //   }
          // }}
          // onTouchEnd={e => {
          //   if (scrollOnTouch) {
          //     const element = document.getElementById(modalId);
          //     counter -= 1;
          //     setTimeout(() => {
          //       if (counter === 0) {
          //         element?.classList.remove(`${styles.mouseEnter}`);
          //       }
          //     }, 300);
          //   }
          // }}
          ref={ref}
          className={`${styles.modalContent} ${contentClassName || ''}`}
          // className={`${styles.modalContent} ${scrollOnTouch && styles.noScrollWithoutTouch}`}
          style={{
            maxHeight: maxHeight,
            width: size.width ? size.width : 'fit-content',
            height: size.height ? size.height : 'fit-content',
            overflow: scrollInside && 'auto',
            ...contentStyle
          }}
          onClick={e => e.stopPropagation()}
          id={modalId}
        >
          {children}
        </div>
      </div>
    </div>
  ) : null;
}
