import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { useTranslation } from 'react-i18next';

import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import TextInput from '../../../../Common/Components/TextInput';
import styles from './PositionInfoContent.module.css';
import SwitchButton from '../../../../Common/Components/CustomSwitchButton/SwitchButton';
import InfoBanner from '../../../../Common/Components/InfoBanner';
import UnlabeledSingleSelectDropdown from '../../../../Common/Components/UnlabeledSingleSelectDropdown/UnlabeledSingleSelectDropdown';
import RadioButton from '../../../../Common/Components/RadioButton';
import isMobile from '../../../../utils/isMobile';
import CustomButton from '../../../../Common/Components/CustomButton';
import { setAssessmentActiveTab } from '../../../../redux/assessmentsVol2/slice';
import { regenerateAssessmentAvailabilityCheckAction } from '../../../../redux/previewAssessment/actions';
import RateLimitModal from '../../Modals/RateLimitModal';
import { generateTestAction } from '../../Api/actions';

const TextArea = ({ descriptionState }) => {
  const { t } = useTranslation(['coensio-ai'])
  return (
    <textarea
      id="job-description-textarea"
      className={styles.jdTextarea}
      placeholder={t('jobDescPholder')}
      onChange={e => {
        descriptionState[1](e.target.value);
      }}
      value={descriptionState[0] || ''}
    />
  );
};

const CommentArea = ({ commentState }) => {
  const { t } = useTranslation(['coensio-ai'])
  return (
    <textarea
      id="additional-specification-textarea"
      className={styles.jdTextarea}
      placeholder={t('addOptionalComment')}
      onChange={e => {
        commentState[1](e.target.value);
      }}
      value={commentState[0] || ''}
    />
  );
};

const PositionInfoContent = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation(['coensio-ai'])

  const langList = [
    { value: 0, label: t('eng'), short: 'EN' },
    { value: 1, label: t('tr'), short: 'TR' }
  ];
  const seniorityList = [
    { value: 10, label: 'Junior' },
    { value: 20, label: 'Mid-Senior' },
    { value: 30, label: 'Senior' }
  ];

  const [selectedLang, setSelectedLang] = useState(langList[0]);

  const [name, setName] = useState();
  const [description, setDescription] = useState();
  const [commentText, setCommentText] = useState();
  const [comment, setComment] = useState(false);
  const [seniority, setSeniority] = useState('Mid-Senior');

  const [showBanner, setShowBanner] = useState(false);
  const [bannerErrorFlag, setBannerErrorFlag] = useState(false);
  const isMobileFlag = isMobile();
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [rateLimitModal, setRateLimitModal] = useState(false);

  async function confirmClicked() {
    await setConfirmLoading(true);

    const isAvailable = await dispatch(regenerateAssessmentAvailabilityCheckAction());
    if (isAvailable?.payload?.isAvailable === true) {
      const data = {
        positionName: name,
        jobDescription: description,
        comment: commentText,
        seniority: seniorityList?.filter(x => x.label === seniority)[0]?.value,
        language: langList?.filter(x => x.value === selectedLang?.value)[0]?.short
      };
      const resp = await generateTestAction(data);

      if (resp?.status === 200) {
        // redirect to homepage
        // error banner
        setBannerErrorFlag(false);
        setShowBanner(true);
        setTimeout(() => {
          dispatch(setAssessmentActiveTab(1));
          history.push('/add-assessment');
        }, 500);
      } else {
        // error banner
        setBannerErrorFlag(true);
        setShowBanner(true);
      }
    } else {
      setRateLimitModal(true);
    }

    setConfirmLoading(false);
  }

  // Sets the comment text area to null when switch button is turned off
  useEffect(() => {
    if (!comment) {
      setCommentText();
    }
  }, [comment]);

  return (
    <>
      <>
        <div className="FlexColumn gap-24 w-100">
        <span className="fw-500 font-12 c-darkGray-05">{t('positionDetail').toLocaleUpperCase('tr')}</span>
          <RadioButton
            containerStyle={{ display: 'grid', gridTemplateColumns: '4fr 5fr 4fr' }}
            data={['Junior', 'Mid-Senior', 'Senior']}
            selectedOption={seniority}
            setSelectedOption={setSeniority}
          />
        </div>
        <TextInput
          style={{ width: '100%' }}
          placeholder={t('jobTitle')}
          onChange={e => setName(e.target.value)}
          value={name || ''}
        />
        <div className={styles.descriptionWrapper}>
          <TextArea descriptionState={[description, setDescription]} />
          <span className={styles.jdExplanation}>
            {t('jobDescNote')}
          </span>
        </div>
      </>

      <div className="FlexColumn gap-8 w-100">
      <span className="fw-500 font-12 c-darkGray-05">{t('assmLang').toLocaleUpperCase('tr')}</span>
        <UnlabeledSingleSelectDropdown
          list={langList}
          selectedValue={selectedLang}
          setSelectedValue={setSelectedLang}
          placeholder={t('language')}
          wrapperStyle={{ width: '100%', height: '48px' }}
        />
      </div>

      <div className="divider" />

      <div className="FlexColumn gap-8">
        <div className="FlexRow gap-12">
          <SwitchButton setSwitchState={setComment} switchState={comment} isMobile={false} />
          <span className={styles.switchLabel}>{t('additionalSpecification')}</span>
        </div>
      </div>

      {comment ? <CommentArea commentState={[commentText, setCommentText]} /> : null}
      <div
        style={{ width: isMobileFlag && '100%', marginRight: 0, marginLeft: 'auto' }}
        className="FlexRow"
      >
        <CustomButton
          customStyle={{ width: isMobileFlag && '100%' }}
          textField={t('confirm')}
          buttonOnClick={() => confirmClicked()}
          isDisabled={
            !name ||
            name?.trim() === '' ||
            !description ||
            description?.trim() === '' ||
            (comment && (!commentText || commentText?.trim() === '')) ||
            !seniority
          }
          loading={confirmLoading}
        />
      </div>

      <RateLimitModal show={rateLimitModal} onHide={setRateLimitModal} />
      <InfoBanner
        show={showBanner}
        text={bannerErrorFlag ? t('errorOccured') : t('stayTuned')}
        setStatus={() => {
          setShowBanner(false);
          setBannerErrorFlag(false);
        }}
      />
    </>
  );
};

export default PositionInfoContent;
