import React, { useState } from 'react';
import EditQuestionDurationModal from '../Modals/EditQuestionDurationModal';

export default function useEditDurationHandler({ rootAssessmentId = null, onUpdateCallback = null }) {
    const [filterShow, setFilterShow] = useState(false);
  const [questionData, setQuestionData] = useState();

  const onClose = () => {
    setFilterShow(false);
    setTimeout(() => {
      setQuestionData();
    }, 0);
  };

  const EditQuestionDurationPopup = (
    <EditQuestionDurationModal
      show={filterShow}
      onClose={onClose}
      questionData={questionData}
      isCustom={questionData?.isCustom}
      isQuestionInOtherAssessment={questionData?.isQuestionInOtherAssessment}
      rootAssessmentId={rootAssessmentId}
      onUpdateCallback={onUpdateCallback}
    />
  );

  return {
    EditQuestionDurationModal: EditQuestionDurationPopup,
    setEditDurationQuestionData: data => {
      setQuestionData(data);
      setFilterShow(true);
    }
  };
}
