import API from '../../constant/apiVol2';
import client from '../../constant/client';

export const getCandidateAssessmentLabelsService = data =>
  new Promise((resolve, reject) => {
    client
      .get(API.getCandidateAssessmentLabels, { params: data })
      .then(resolve)
      .catch(error => {
        console.log(error);
        reject(error);
      });
  });

export const getAssessmentListForCandidateServiceVol2 = data =>
  new Promise((resolve, reject) => {
    client.post(API.getAssessmentListForCandidate, data).then(resolve).catch(reject);
  });

export const getCandidateSolutionsService = data =>
  new Promise((resolve, reject) => {
    client.get(API.getCandidateSolutions, { params: data }).then(resolve).catch(reject);
  });

export const getPitTestResultsService = data =>
  new Promise((resolve, reject) => {
    client.get(API.getPitTestResults, { params: data }).then(resolve).catch(reject);
  });

export const sendPitTestToCandidateService = data =>
  new Promise((resolve, reject) => {
    client.post(API.sendPitTestToCandidate, data).then(resolve).catch(reject);
  });

export const getCameraShotsService = data =>
  new Promise((resolve, reject) => {
    client.get(API.getCameraShots, { params: data }).then(resolve).catch(reject);
  });

export const getHrPeakTestResultsService = data =>
  new Promise((resolve, reject) => {
    client.get(API.getHrPeakTestResults, { params: data }).then(resolve).catch(reject);
  });

export const sendHrPeakTestToCandidateService = data =>
  new Promise((resolve, reject) => {
    client.post(API.sendHrPeakTestToCandidate, data).then(resolve).catch(reject);
  });

export const getCaseStudyService = data =>
  new Promise((resolve, reject) => {
    client.get(API.getCaseStudy, { params: data }).then(resolve).catch(reject);
  });

export const uploadCaseStudyService = data =>
  new Promise((resolve, reject) => {
    client.post(API.uploadCaseStudy, data).then(resolve).catch(reject);
  });
