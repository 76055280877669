import { createAsyncThunk } from '@reduxjs/toolkit';
import { editQuestionDurationService, getJobDescriptionListService } from './service';

export const getJobDescriptionListAction = async data => {
  try {
    return await getJobDescriptionListService(data);
  } catch (error) {
    return null;
  }
};

export const editQuestionDurationAction = createAsyncThunk(
  'assessmentCreation/editQuestionDuration',
  async (data, thunkAPI) => {
    try {
      const resp = await editQuestionDurationService(data);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);