import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';

import { useTranslation, Trans } from 'react-i18next';

import styles from '../Styles/QuestionPreview.module.css';
import constants from '../../../utils/constants';
import CustomButton from '../../../Common/Components/CustomButton';
import RemoveQuestionModal from './Modals/RemoveQuestionModal/RemoveQuestionModal';
import RegenerateModal from './Modals/RegenerateModal/RegenerateModal';
import TextInput from '../../../Common/Components/TextInput';
import { editDraftQuestionAction } from '../../../redux/previewAssessment/actions';
import { ReactComponent as EditIcon } from '../../../images/AssessmentDetail/editIcon.svg';

function getMinutesAndSeconds(time) {
  if (!time) return { min: 0, seconds: 0 };
  const duration = Number(time);
  const seconds = duration % 60;
  const min = Math.floor(duration / 60);
  return { min, seconds };
}

const QuestionPreview = ({
  questionDetail,
  index,
  willRegenerated,
  setRegenerated,
  isRegenerateEnabled,
  removeHandler,
  refetchList
}) => {
  const {
    assessmentLabel,
    category,
    codingLanguage,
    correctAnswer,
    descHtmlJson,
    difficulty,
    isNew,
    optionsHtmlJson,
    questionId,
    questionLanguage,
    questionTypeId,
    reservedName,
    subCategory,
    time,
    weight
  } = questionDetail;

  const dispatch = useDispatch();

  const [showRemove, setShowRemove] = useState(false);
  const [showRegenerate, setShowRegenerate] = useState(false);
  const [showEditButton, setShowEditButton] = useState(false);
  const [editTime, setEditTime] = useState(false);
  const [saveButtonLoading, setSaveButtonLoading] = useState(false);
  const [updatedDuration, setUpdatedDuration] = useState(time);

  const { t } = useTranslation(['assmCustomization'])

  function timeCalculator(time) {
    const duration = Number(time);
    const seconds = duration % 60;
    const min = Math.floor(duration / 60);

    if (duration >= 60)
      return seconds ? (
        <Trans i18nKey="qDurationMinSec" ns="assmCustomization" values={{ min: min, sec: seconds }}>
          <span>1</span>
          <span>2</span>
          <span>3</span>
          <span>4</span>
        </Trans>
      ) : (
        <Trans i18nKey="qDurationMin" ns="assmCustomization" values={{ min: min }}>
          <span>1</span>
          <span>2</span>
        </Trans>
      );
    return (
      <Trans i18nKey="qDurationSec" ns="assmCustomization" values={{ sec: seconds }}>
        <span>1</span>
        <span>2</span>
      </Trans>
    );

  }

  const setQuestionRegenerated = comment => {
    setRegenerated(comment);
  };

  const QuestionPreviewHeader = useCallback(
    () => (
      <div className={styles.QuestionPreviewHeader}>
        <div className={styles.QuestionPreviewHeaderInner}>
          <span className={styles.QuestionPreviewIndex}>{index + 1}</span>
          <span>{reservedName}</span>
        </div>
        <div className={styles.QuestionPreviewHeaderInner2}>
          <span>{constants.QUESTION_TYPE_ID[questionTypeId]}</span>
          <span className={styles.QuestionPreviewDifficulty}>{difficulty}</span>
        </div>
      </div>
    ),
    []
  );

  const duration = timeCalculator(updatedDuration);

  const regenerateQuestionHandler = () => {
    if (questionTypeId === 2) {
      setQuestionRegenerated('');
    } else {
      setShowRegenerate(true);
    }
  };

  const parsedDescriptionHelper = x => {
    try {
      return JSON.parse(x);
    } catch (error) {
      return [];
    }
  };

  const removeHelper = () => {
    if (willRegenerated) {
      removeHandler(questionId);
    }
  };

  const editDuration = () => {
    if (questionTypeId === 2) {
      setShowEditButton(false);
    }
  };

  const updateTimeHelper = async () => {
    setSaveButtonLoading(true);
    await dispatch(editDraftQuestionAction({ questionId, duration: updatedDuration })).then(
      resp => {
        if (resp?.meta?.requestStatus === 'fulfilled') {
          setEditTime(false);
          refetchList();
        } else {
          alert(
            t('errorOccured')
          );
        }
      }
    );
    setSaveButtonLoading(false);
  };

  const QuestionPreviewBody = useCallback(
    () =>
      !willRegenerated ? (
        <>
          <span
            className={styles.QuestionPreviewDesctiption}
            dangerouslySetInnerHTML={{ __html: parsedDescriptionHelper(descHtmlJson) }}
          />
          {questionTypeId === 1 && (
            <div className={styles.QuestionPreviewOptionsWrapper}>
              {parsedDescriptionHelper(optionsHtmlJson)?.map((x, index) => (
                <div className={styles.QuestionPreviewOptionItem}>
                  <div className={styles.QuestionPreviewCheckbox}>
                    {index === correctAnswer && (
                      <div className={styles.QuestionPreviewCheckboxCorrect} />
                    )}
                  </div>
                  <span
                    className={styles.QuestionPreviewOption}
                    dangerouslySetInnerHTML={{ __html: x }}
                  />
                </div>
              ))}
            </div>
          )}
          <div className={styles.QuestionPreviewBorder} />
        </>
      ) : null,
    [willRegenerated]
  );

  const QuestionPreviewContent = (
    <div className={styles.QuestionPreviewContent}>
      <QuestionPreviewBody />
      <div className={styles.QuestionPreviewBottomRow}>
        {willRegenerated ? (
          <span className={styles.QuestionPreviewRegenerateText}>
            {t('selectRegeneration')}
          </span>
        ) : (
          <div className={styles.QuestionPreviewTime}>
            {t('time')}
            {editTime ? (
              <>
                <TextInput
                  hidePlaceholder
                  type="number"
                  value={
                    updatedDuration
                      ? getMinutesAndSeconds(updatedDuration).min === 0
                        ? ''
                        : getMinutesAndSeconds(updatedDuration).min
                      : ''
                  }
                  onChange={e => {
                    setUpdatedDuration(
                      d => getMinutesAndSeconds(d).seconds + Number(e.target.value) * 60
                    );
                  }}
                  suffix={t('min')}
                  inputstyle={{ width: '80px', height: '36px' }}
                />
                <TextInput
                  hidePlaceholder
                  type="number"
                  value={
                    updatedDuration
                      ? getMinutesAndSeconds(updatedDuration).seconds === 0
                        ? ''
                        : getMinutesAndSeconds(updatedDuration).seconds
                      : ''
                  }
                  suffix={t('sec')}
                  onChange={e => {
                    setUpdatedDuration(
                      d => getMinutesAndSeconds(d).min * 60 + Number(e.target.value)
                    );
                  }}
                  inputstyle={{ width: '80px', height: '36px' }}
                />
                <CustomButton
                  type={4}
                  size="small"
                  textField={t('save')}
                  buttonOnClick={updateTimeHelper}
                  loading={saveButtonLoading}
                  customStyle={{
                    backgroundColor: 'transparent',
                    paddingLeft: '0px',
                    paddingRight: '0px'
                  }}
                />
              </>
            ) : (
              <>
                <span>{duration}</span>
                {questionTypeId !== 2 && (
                  <EditIcon
                    className={styles.editIcon2}
                    style={{ marginRight: '18px', cursor: 'pointer' }}
                    onClick={() => setEditTime(true)}
                  />
                )}
              </>
            )}
          </div>
        )}
        <div className={styles.QuestionPreviewButtonWrapper}>
          <CustomButton
            textField={t('remove')}
            size="small"
            type={7}
            customStyle={{
              backgroundColor: 'transparent',
              paddingLeft: '0px',
              paddingRight: '0px'
            }}
            buttonOnClick={() => setShowRemove(true)}
          />
          {!willRegenerated && (
            <CustomButton
              textField={t('regenerate')}
              size="small"
              type={4}
              customStyle={{
                backgroundColor: 'transparent',
                paddingLeft: '0px',
                paddingRight: '0px'
              }}
              buttonOnClick={regenerateQuestionHandler}
              isDisabled={!isRegenerateEnabled}
              tooltipText={t('exceedLimit')}
            />
          )}
        </div>
      </div>
    </div>
  );

  const Modals = (
    <>
      <RemoveQuestionModal
        questionId={questionId}
        show={showRemove}
        onClose={() => setShowRemove(false)}
        regenerateHandler={removeHelper}
      />
      <RegenerateModal
        show={showRegenerate}
        onClose={() => setShowRegenerate(false)}
        questionId={questionId}
        setQuestionAsRegenerated={setQuestionRegenerated}
      />
    </>
  );

  return (
    <>
      <div className={styles.QuestionPreviewWrapper}>
        <QuestionPreviewHeader />
        {QuestionPreviewContent}
      </div>
      {Modals}
    </>
  );
};

export default QuestionPreview;
