import client from '../../constant/client';
import API from '../../constant/apiVol2';

export const previewDraftAssessmentCheckService = data =>
  new Promise((resolve, reject) => {
    client.get(API.previewDraftAssessmentCheck, { params: data }).then(resolve).catch(reject);
  });

  export const editDraftQuestionService = data =>
  new Promise((resolve, reject) => {
    client.post(API.editQuestion, data).then(resolve).catch(reject);
  });

  export const removeDraftQuestionService = data =>
  new Promise((resolve, reject) => {
    client.post(API.removeDraftQuestion, data).then(resolve).catch(reject);
  });
  
  export const regenerateQuestionsService = data =>
  new Promise((resolve, reject) => {
    client.post(API.regenerateQuestions, data).then(resolve).catch(reject);
  });
  
  export const publishAssessmentService = data =>
  new Promise((resolve, reject) => {
    client.post(API.publishAssessment, data).then(resolve).catch(reject);
  });
  
  export const regenerateAssessmentService = data =>
  new Promise((resolve, reject) => {
    client.post(API.regenerateAssessment, data).then(resolve).catch(reject);
  });

  export const regenerateAssessmentAvailabilityCheckService = data =>
  new Promise((resolve, reject) => {
    client.get(API.regenerateAssessmentAvailabilityCheck, { params: data }).then(resolve).catch(reject);
  });
  
  export const regenerateQuestionAvailabilityCheckService = data =>
  new Promise((resolve, reject) => {
    client.get(API.regenerateQuestionAvailabilityCheck, { params: data }).then(resolve).catch(reject);
  });
  
  