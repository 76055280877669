import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isMobile } from 'react-device-detect';

import { useTranslation, Trans } from 'react-i18next';

import InfoBanner from '../../../../Common/Components/InfoBanner';
import { setErrorInTeam, setSuccessInTeam } from '../../../../redux/auth/slice';
import CustomButton from '../../../../Common/Components/CustomButton';
import { ReactComponent as NewUser } from '../../../Images/newuserIcon.svg';
import TeamMember from './TeamMember';
import {
  errorInTeamRedux,
  errorMessageInTeamRedux,
  successInTeamRedux,
  successMessageInTeamRedux,
  teamRedux,
  userSelector
} from '../../../../redux/auth/selectors';
import styles from './TeamTab.module.css';
import EditUserModal from '../Modals/EditUserModal';
import { getTeamAction } from '../../../../redux/auth/actions';
import { getUserGroupTypeListAction } from '../../../../redux/user/actions';
import { ReactComponent as Error } from '../../../../images/Common/errorSubmit.svg';
import CustomSearchbar from '../../../../Common/Components/CustomSearchbar/CustomSearchbar';
import Skeleton from '../../../../Common/Components/Skeleton';

const TeamTab = () => {
  const dispatch = useDispatch();
  const addUserRef = useRef();

  const user = useSelector(userSelector);
  const team = useSelector(teamRedux);
  const teamActionError = useSelector(errorInTeamRedux);
  const teamErrorMessage = useSelector(errorMessageInTeamRedux);
  const teamActionSuccess = useSelector(successInTeamRedux);
  const teamSuccessMessage = useSelector(successMessageInTeamRedux);

  const [addUserShow, setAddUserShow] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [translatedTeam, setTranslatedTeam] = useState([]);

  const { t, i18n } = useTranslation(['teamTab']);

  useEffect(() => {
    if (team && team.companyUsers && team.companyUsers.length > 0) {
      if (i18n.language === 'tr') {
        setTranslatedTeam(
          team.companyUsers.map(user => {
            if (user.role === 'Hiring Manager') {
              return {
                ...user,
                role: 'Teknik Yönetici'
              };
            } if (user.role === 'Recruiter') {
              return {
                ...user,
                role: 'İşe Alım Uzmanı'
              };
            }

            return user;
          })
        );
      } else {
        setTranslatedTeam(team.companyUsers);
      }
    }
  }, [i18n.language, team]);

  useEffect(async () => {
    dispatch(getTeamAction({ CompanyId: user?.companyId }));
    await dispatch(getUserGroupTypeListAction());
  }, []);

  return (
    <div className={styles.contentBody}>
      <InfoBanner
        text={teamErrorMessage !== '' ? teamErrorMessage : t('errorOccured')}
        show={teamActionError}
        CustomIcon={Error}
        setStatus={type => {
          dispatch(setErrorInTeam(type));
        }}
        bannerStyle={{ backgroundColor: 'white', border: '1px solid rgba(221, 58, 58, 0.1)' }}
      />

      <InfoBanner
        text={teamSuccessMessage}
        show={teamActionSuccess}
        setStatus={type => {
          dispatch(setSuccessInTeam(type));
        }}
      />
      <EditUserModal show={addUserShow} onClose={() => setAddUserShow(false)} type="addNewUser" />
      <div className={styles.contentWrapper}>
        <div className={styles.headerWrapper}>
          <div className={styles.title}>{t('team')}</div>
          <CustomButton
            buttonId="addNewUserButtonId"
            buttonRef={addUserRef}
            textField={t('addUser')}
            type={1}
            Icon={NewUser}
            size="small"
            customStyle={{ height: '35px' }}
            buttonOnClick={() => {
              setAddUserShow(true);
            }}
          />
        </div>
        {isMobile ? (
          <div className={styles.pageWrapper} id="team-page">
            <div className={styles.searchbarWrapper}>
              {team ? (
                <span className={styles.usersText}>
                  <span>
                    <Trans i18nKey="userCount" ns="teamTab" values={{ userCount: team.count }}>
                      0<span>1</span>
                      <span>2</span>
                    </Trans>
                  </span>
                </span>
              ) : (
                <span />
              )}
              <div style={{ width: '80%' }}>
                <CustomSearchbar
                  isExtended
                  inputFieldPlaceholder={t('search')}
                  inputFieldValue={searchTerm}
                  inputFieldOnChange={event => {
                    setSearchTerm(event.target.value);
                  }}
                  wrapperStyle={{ background: '#f4f4f499' }}
                />
              </div>
            </div>
            <div className={styles.titleWrapper}>
              <span style={{ width: '50%' }}>{t('nameTitle')}</span>
            </div>
            <div className={styles.usersWrapper} id="usersWrapper">
              {translatedTeam ? (
                translatedTeam.map(
                  (element, index) =>
                    element.name
                      ?.concat(' ')
                      ?.concat(element.surname)
                      ?.concat(' ')
                      ?.concat(element.email)
                      ?.toLowerCase()
                      ?.includes(searchTerm?.toLowerCase()) && (
                      <TeamMember
                        name={element?.name}
                        surname={element?.surname}
                        role={element.role}
                        invited={!element.isActive}
                        isAdmin={element?.isAdmin}
                        userId={element?.userId}
                        companyId={user?.companyId}
                        email={element?.email}
                        key={index}
                        teamUser={element}
                      />
                    )
                )
              ) : (
                <Skeleton type="settingsTeamTab" />
              )}
            </div>
          </div>
        ) : (
          <div className={styles.pageWrapper} id="team-page">
            <div className={styles.searchbarWrapper}>
              {team ? (
                <span className={styles.usersText}>
                  <span>
                    <Trans i18nKey="userCount" ns="teamTab" values={{ userCount: team.count }}>
                      0<span>1</span>
                      <span>2</span>
                    </Trans>
                  </span>
                </span>
              ) : (
                <span />
              )}
              <div style={{ width: '55%' }}>
                <CustomSearchbar
                  isExtended
                  inputFieldPlaceholder={t('search')}
                  inputFieldValue={searchTerm}
                  inputFieldOnChange={event => {
                    setSearchTerm(event.target.value);
                  }}
                  wrapperStyle={{ background: '#f4f4f499' }}
                />
              </div>
            </div>
            <div className={styles.titleWrapper}>
              <span style={{ width: '50%' }}>{t('nameTitle')}</span>
              <span style={{ width: '185px', marginLeft: 'auto' }}>{t('roleTitle')}</span>
            </div>
            <div className={styles.usersWrapper} id="usersWrapper">
              {translatedTeam ? (
                translatedTeam.map(
                  (element, index) =>
                    element.name
                      ?.concat(' ')
                      ?.concat(element.surname)
                      ?.concat(' ')
                      ?.concat(element.email)
                      ?.toLowerCase()
                      ?.includes(searchTerm?.toLowerCase()) && (
                      <TeamMember
                        name={element?.name}
                        surname={element?.surname}
                        role={element.role}
                        invited={!element.isActive}
                        isAdmin={element?.isAdmin}
                        userId={element?.userId}
                        companyId={user?.companyId}
                        email={element?.email}
                        key={index}
                        teamUser={element}
                      />
                    )
                )
              ) : (
                <Skeleton type="settingsTeamTab" />
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default TeamTab;
