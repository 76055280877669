import { createSlice } from '@reduxjs/toolkit';
import {
  applicantAssessmentDetailAction,
  applicantAssessmentDetailWithEmailAction,
  candidateContractCheckAction,
  ChangeOrAddApplicantDetailAction,
  getAssessmentSessionDetailAction,
  getCandidateInfoFromEmailAction,
  getCurrentQuestionAction,
  getNextQuestionAction,
  getScreeningQuestionsAction,
  startAssessmentSessionAction,
  submitCodingQuestionAnswerAction,
  submitFreeTextQuestionAnswerAction,
  submitMultipleChoiceQuestionAnswerAction,
  candidateHasDataAction
} from './actions';
import initialState from './initialState';

const assessmentSessionSlice = createSlice({
  name: 'assessmentSession',
  initialState,
  reducers: {
    setSaveButtonLoading: {
      reducer: (state, action) => {
        state.saveLoading = action.payload;
      }
    },
    setCandidateEmail: {
      reducer: (state, action) => {
        state.candidateEmail = action.payload;
        localStorage.setItem('candidateEmail', action.payload);
      }
    },
    setName: {
      reducer: (state, action) => {
        state.candidateName = action.payload;
      }
    },
    setSurname: {
      reducer: (state, action) => {
        state.candidateSurname = action.payload;
      }
    },
    setPhone: {
      reducer: (state, action) => {
        state.candidatePhone = action.payload;
      }
    },
    setLinkedIn: {
      reducer: (state, action) => {
        state.candidateLinkedIn = action.payload;
      }
    },
    setCv: {
      reducer: (state, action) => {
        state.cvPath = action.payload;
      }
    },
    setApplicationState: {
      reducer: (state, action) => {
        state.applicationState = action.payload;
      }
    },
    setBannerStatus: {
      reducer: (state, action) => {
        state.showBanner = action.payload;
      }
    },
    setBannerText: {
      reducer: (state, action) => {
        state.bannerText = action.payload;
      }
    },
    setLoading: {
      reducer: (state, action) => {
        state.loading = action.payload;
      }
    },

    resetQuestionInfo: (state, action) => {
      state.questionInfo = action.payload;
    },

    // Screening related
    setScreeningWorkTypeList: (state, action) => {
      state.screeningWorkTypeList = action.payload;
    },
    setScreeningSalaryExpectation: (state, action) => {
      state.screeningSalaryExpectation = action.payload;
    },
    setScreeningNationalityIds: (state, action) => {
      state.screeningNationalityIds = action.payload;
    },
    setScreeningCurrencyType: (state, action) => {
      state.screeningCurrencyType = action.payload;
    },
    setScreeningExperience: (state, action) => {
      state.screeningExperience = action.payload;
    },
    setScreeningLocation: (state, action) => {
      state.screeningLocation = action.payload;
    }
  },

  extraReducers: builder => {
    builder.addCase(applicantAssessmentDetailAction.fulfilled, (state, action) => {
      state.assessmentId = action.payload.id;
      state.cvCheck = action.payload.isCvNeeded;
      state.screeningCheck = action.payload.isScreeningNeeded;
      state.logoPath = action?.payload.logoUrl;
      localStorage.setItem('companyName', action.payload.companyName);
      state.companyName = action.payload.companyName;
      state.assessmentTitle = action.payload.companyAssessmentName;
      state.questionCount = action.payload.questionCount;
      state.totalTime = action.payload.totalTime;
      state.languageList = action.payload.questionLanguagesList;
      state.expired = action.payload.companyAssessmentObjStatusId === 2;
      // state.loading = false;
      state.codingCount =
        action.payload.questionTypeList.filter(x => x.questionTypeIdInt === 2)[0]
          ?.questionCountByTypes || 0;
      state.freeCount =
        action.payload.questionTypeList.filter(x => x.questionTypeIdInt === 3)[0]
          ?.questionCountByTypes || 0;
      state.multiCount =
        action.payload.questionTypeList.filter(x => x.questionTypeIdInt === 1)[0]
          ?.questionCountByTypes || 0;
      state.multiCorrectCount =
        action.payload.questionTypeList.filter(x => x.questionTypeIdInt === 4)[0]
          ?.questionCountByTypes || 0;

      state.rootAssessmentName = action.payload.rootAssessmentName;
      state.hasAssessment = action.payload.hasAssessment;
      state.descriptionEN = action.payload.descriptionEN;
      state.descriptionTR = action.payload.descriptionTR;
      state.positionHeaderEN = action.payload.positionHeaderEN;
      state.positionHeaderTR = action.payload.positionHeaderTR;
      state.dueDate = action.payload.dueDate;

      state.isCvNeeded = action.payload.isCvNeeded;
      state.isScreeningNeeded = action.payload.isScreeningNeeded;
      state.isCameraNeeded = action.payload.isCameraNeeded;

      state.isOtpNeeded = action.payload.isOtpNeeded;
      localStorage.setItem('isOtpNeeded', action.payload.isOtpNeeded);
    });
    builder.addCase(applicantAssessmentDetailAction.pending, state => {
      state.id = null;
      state.logoPath = null;
      state.companyName = null;
      state.loading = true;
      state.assessmentTitle = null;
      state.questionCount = null;
      state.totalTime = null;
      state.languageList = null;
      state.codingCount = 0;
      state.multiCount = 0;
      state.freeCount = 0;

      state.rootAssessmentName = null;
      state.hasAssessment = null;
      state.descriptionEN = null;
      state.descriptionTR = null;
      state.positionHeaderEN = null;
      state.positionHeaderTR = null;
      state.dueDate = null;

      state.isCameraNeeded = false;
      state.isCvNeeded = true;
      state.isScreeningNeeded = true;
    });

    builder.addCase(applicantAssessmentDetailWithEmailAction.rejected, state => {
      state.loading = false;
    });
    builder.addCase(applicantAssessmentDetailAction.rejected, state => {
      state.loading = false;
    });

    builder.addCase(candidateHasDataAction.fulfilled, (state, action) => {
      state.hasInformation = action.payload.hasInformation;

      //   "userId": "405970",
      // "cvPath": null,
      // "linkedIn": "https://linkedin.com/in/seyfullah-akgun",
      // "phone": "905442827393",
      // "name": null,
      // "surname": null,
      // "isAlreadyPassed": false,
      // "hasInformation": false,
      // "email": "seyfullah.akgn@gmail.com",
      // "reserved": null
    });

    builder.addCase(applicantAssessmentDetailWithEmailAction.fulfilled, (state, action) => {
      localStorage.setItem('candidateEmail', action.payload.email);
      state.candidateEmail = action.payload.email;
      state.candidateName = action.payload.name;
      state.candidateSurname = action.payload.surname;
      state.loading = false;
      state.candidateLinkedIn = action.payload.linkedIn;
      state.candidatePhone = action.payload.phone;
      state.cvPath = action.payload.cvPath;
      state.cvCheck = action.payload.isCvNeeded;
      state.screeningCheck = action.payload.isScreeningNeeded;
      state.contentTemplates = action.payload.contentTemplates;
      state.userId = action.payload.userId;
    });

    builder.addCase(getCandidateInfoFromEmailAction.fulfilled, (state, action) => {
      state.candidateName = action.payload.name;
      state.candidateSurname = action.payload.surname;
      state.loading = false;
      state.candidateLinkedIn = action.payload.linkedIn;
      state.candidatePhone = action.payload.phone;
      state.cvPath = action.payload.cvPath;

      state.cvCheck = action.payload.isCvNeeded;
      state.screeningCheck = action.payload.isScreeningNeeded;
    });

    builder.addCase(candidateContractCheckAction.fulfilled, (state, action) => {
      state.showContract = !action.payload;
    });
    builder.addCase(startAssessmentSessionAction.fulfilled, (state, action) => {
      localStorage.setItem('assessmentSessionToken', action.payload.assessmentSessionToken);
      state.assessmentSessionToken = action.payload.assessmentSessionToken;
    });
    builder.addCase(getNextQuestionAction.fulfilled, (state, action) => {
      state.questionInfo = action.payload;
      state.nextButtonLoading = false;
    });
    builder.addCase(getCurrentQuestionAction.fulfilled, (state, action) => {
      state.questionInfo = action.payload;
      state.nextButtonLoading = false;
    });
    builder.addCase(submitMultipleChoiceQuestionAnswerAction.pending, state => {
      state.nextButtonLoading = true;
    });
    builder.addCase(submitFreeTextQuestionAnswerAction.pending, state => {
      state.nextButtonLoading = true;
    });
    builder.addCase(submitCodingQuestionAnswerAction.pending, state => {
      state.nextButtonLoading = true;
    });
    builder.addCase(submitMultipleChoiceQuestionAnswerAction.rejected, state => {
      state.nextButtonLoading = false;
    });
    builder.addCase(submitFreeTextQuestionAnswerAction.rejected, state => {
      state.nextButtonLoading = false;
    });
    builder.addCase(submitCodingQuestionAnswerAction.rejected, state => {
      state.nextButtonLoading = false;
    });
    builder.addCase(ChangeOrAddApplicantDetailAction.pending, state => {
      state.saveLoading = true;
    });
    builder.addCase(ChangeOrAddApplicantDetailAction.fulfilled, state => {
      state.saveLoading = false;
    });
    builder.addCase(ChangeOrAddApplicantDetailAction.rejected, state => {
      state.saveLoading = false;
    });
    builder.addCase(getAssessmentSessionDetailAction.fulfilled, (state, action) => {
      state.logoPath = action.payload.companyLogo;
      state.assessmentTitle = action.payload.companyAssessmentName;
    });

    builder.addCase(getScreeningQuestionsAction.pending, state => {
      state.screeningLoading = true;
    });
    builder.addCase(getScreeningQuestionsAction.rejected, state => {
      state.screeningLoading = false;
      state.screeningListObj = null;
    });
    builder.addCase(getScreeningQuestionsAction.fulfilled, (state, action) => {
      state.screeningLoading = false;
      state.screeningListObj = action.payload;
    });
  }
});

export const {
  setCandidateEmail,
  setName,
  setLinkedIn,
  setPhone,
  setSurname,
  setCv,
  setApplicationState,
  setBannerStatus,
  setBannerText,
  setSaveButtonLoading,
  setLoading,
  resetQuestionInfo,
  setScreeningWorkTypeList,
  setScreeningSalaryExpectation,
  setScreeningNationalityIds,
  setScreeningCurrencyType,
  setScreeningExperience,
  setScreeningLocation
} = assessmentSessionSlice.actions;

export default assessmentSessionSlice.reducer;
