import React, { useEffect, useState, useRef } from 'react';
import { useWindowSize } from '../../../utils/helpers';
import Tooltip from '../Tooltip';

const TextOverflow = ({
  children,
  text,
  showTooltip,
  style,
  className,
  parentStyle,
  tooltipDirection,
  tooltipTheme,
  ...props
}) => {
  const [clipped, setClipped] = useState(false);
  const [textWidth, setTextWidth] = useState(0);

  const [width] = useWindowSize();

  const parentRef = useRef(null);
  const elementRef = useRef(null);

  useEffect(() => {
    const element = elementRef.current;
    if (!element) return undefined; // Explicitly return undefined
    // Compute width after rendering
    setTextWidth(element.scrollWidth);
    // Add event listener for resize
    const handleResize = () => {
      setTextWidth(element.scrollWidth);
    };
    window.addEventListener('resize', handleResize);
    // Return cleanup function
    return () => {
      window.removeEventListener('resize', handleResize);
      return undefined; // Explicitly return undefined
    };
  }, [text, width, elementRef]);

  useEffect(() => {
    const element = parentRef.current;
    if (!element) return;

    // Check if text is clipped
    if (textWidth > element.offsetWidth) {
      setClipped(true);
    } else {
      setClipped(false);
    }
  }, [textWidth, parentRef]);

  return (
    <div
      ref={parentRef}
      style={{
        display: 'flex',
        alignItems: 'center',
        overflow: 'hidden',
        ...parentStyle
      }}
      {...props}
    >
      <Tooltip
        direction={tooltipDirection}
        content={text}
        show={showTooltip && clipped}
        theme={tooltipTheme}
      >
        <div
          ref={elementRef}
          style={{
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            ...style
          }}
          className={className}
        >
          {children || text}
        </div>
      </Tooltip>
    </div>
  );
};

TextOverflow.defaultProps = {
  showTooltip: true,
  style: {},
  className: '',
  tooltipDirection: 'top',
  tooltipTheme: 'light'
};

export default TextOverflow;
