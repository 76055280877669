/* eslint-disable no-return-assign */
import React, { useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { useTranslation } from 'react-i18next';

import styles from '../Styles/SettingsBar.module.css';
import { ReactComponent as PersonalIcon } from '../../images/Auth/Settings/personalSettings.svg';
import { ReactComponent as CompanyIcon } from '../../images/Auth/Settings/companySettings.svg';
import { useWindowSize } from '../../utils/helpers';
import SettingsTitle from './Settings/SettingsTitle';
import SettingsCategoryTitle from './Settings/SettingsCategoryTitle';
import SettingsTab from './Settings/SettingsTab';
import useDisableBilling from '../Utils/useDisableBilling';

function SettingsBar() {
  const { isBillingDisabled } = useDisableBilling();

  const { t } = useTranslation(['settingsBar']);

  return (
    <div className="FlexColumn gap-20" style={{ paddingLeft: '10%' }}>
      <SettingsTitle />

      <SettingsCategoryTitle
        title={t('personal')}
        icon={
          <PersonalIcon
            className="FlexRow verticallyCenter"
            style={{ width: '17px', height: '17px' }}
          />
        }
      />
      <SettingsTab title={t('myProfile')} redirect="myProfile" />

      <SettingsCategoryTitle
        title={t('company')}
        icon={
          <CompanyIcon
            className="FlexRow verticallyCenter"
            style={{ width: '17px', height: '17px' }}
          />
        }
        style={{ marginTop: '40px' }}
      />
      <SettingsTab title={t('general')} redirect="general" />
      <SettingsTab title={t('team')} redirect="team" />
      <SettingsTab title={t('position')} redirect="position" />
      <SettingsTab title={t('plan')} redirect="plan" />
      <SettingsTab
        title={t('billing')}
        redirect="billing"
        textStyle={
          isBillingDisabled
            ? {
                color: 'rgba(57, 57, 57, 0.15)',
                cursor: 'auto',
                pointerEvents: 'none'
              }
            : {}
        }
      />
    </div>
  );
}

function SettingsTabSwitch() {
  const { t } = useTranslation(['settingsBar']);

  const tabs = [t('myProfileTab'), t('general'), t('team'), t('plan'), t('billing')];
  const tabsRedirect = ['myProfile', 'general', 'team', 'plan', 'billing']
  const [selectedTabIndex, setSelectedTabIndex] = useState();
  const [tabUnderlineWidth, setTabUnderlineWidth] = useState(0);
  const [tabUnderlineLeft, setTabUnderlineLeft] = useState(0);
  const tabsRef = useRef([]);
  const [windowW] = useWindowSize();
  const { tab } = useParams();
  const history = useHistory();
  const [leftBlur, setLeftBlur] = useState(false);
  const [rightBlur, setRightBlur] = useState(false);

  const { isBillingDisabled } = useDisableBilling();

  function handleBlurShow() {
    const tabs = document?.getElementById('scrollable-mobile-settings-tab-switch')?.getBoundingClientRect();
    const settingsbar = document?.getElementById('settings-bar-container')?.getBoundingClientRect();
    const billingTab = document?.getElementById('settings-mobile-tab-Billing')?.getBoundingClientRect();
    if (tabs?.right < billingTab?.right) {
      setRightBlur(true);
    } else {
      setRightBlur(false);
    }
    if (tabs?.left > settingsbar?.left) {
      setLeftBlur(true);
    } else {
      setLeftBlur(false);
    }
  }

  useEffect(() => {
    tabsRedirect?.forEach((tabName, index) => {
      if (tabName.replace(' ', '').toLowerCase() === tab) {
        setSelectedTabIndex(index);
      }
    });
  }, [tab]);

  useEffect(() => {
    handleBlurShow();
    function setTabPosition() {
      const currentTab = tabsRef.current[selectedTabIndex];
      setTabUnderlineLeft(currentTab?.offsetLeft ?? 0);
      setTabUnderlineWidth(currentTab?.clientWidth ?? 0);
    }

    setTabPosition();
    window.addEventListener('resize', setTabPosition);

    return () => window.removeEventListener('resize', setTabPosition);
  }, [selectedTabIndex, windowW]);

  return (
    <>
      <div style={{ opacity: leftBlur ? 1 : 0 }} className={styles.blurLeft} />
      <div
        id="scrollable-mobile-settings-tab-switch"
        className={styles.mobileWrapper}
        onScrollCapture={() => handleBlurShow()}
      >
        <div className={styles.container} id="settings-bar-container">
          {tabs?.map((tabName, index) => (
            <>
              <div
                id={`settings-mobile-tab-${tabsRedirect[index]}`}
                ref={el => (tabsRef.current[index] = el)}
                className={`${styles.mobileTabWrapper} ${
                  index === selectedTabIndex ? styles.active : ''
                } ${isBillingDisabled && tabsRedirect[index] === 'Billing' && styles.disabled}`}
                onClick={() => {
                  if (!(isBillingDisabled && tabsRedirect[index] === 'Billing')) {
                    setSelectedTabIndex(index);
                    history.replace(`/settings/${tabsRedirect[index].toLowerCase().replace(' ', '')}`);
                  }
                }}
              >
                {tabName}
              </div>
              <span
                className={styles.borderBottom}
                style={{ left: tabUnderlineLeft, width: tabUnderlineWidth }}
              />
            </>
          ))}
        </div>
      </div>
      <div style={{ opacity: rightBlur ? 1 : 0 }} className={styles.blurRight} />
    </>
  );
}

function SettingsMenu() {
  const [screenW] = useWindowSize();
  return (
    <div className={styles.barContainer}>
      {screenW > 576 ? <SettingsBar /> : <SettingsTabSwitch />}
    </div>
  );
}
export default SettingsMenu;
