import React, { useEffect, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation, Trans } from 'react-i18next';
import {
  detailObjSelector,
  regenerateAssessmentAvailabilitySelector,
  regenerateQuestionRemainingRightSelector
} from '../../../redux/previewAssessment/selectors';
import {
  previewDraftAssessmentCheckAction,
  publishAssessmentAction,
  regenerateAssessmentAction,
  regenerateAssessmentAvailabilityCheckAction,
  regenerateQuestionAvailabilityCheckAction,
  regenerateQuestionsAction
} from '../../../redux/previewAssessment/actions';

import styles from '../Styles/PreviewAssessment.module.css';
import CustomButton from '../../../Common/Components/CustomButton';
import SharePreviewModal from './Modals/SharePreviewModal/SharePreviewModal';
import QuestionPreview from './QuestionPreview';
import RegenerateModal from './Modals/RegenerateModal/RegenerateModal';
import route from '../../../constant/route';
import { setAssessmentActiveTab } from '../../../redux/assessmentsVol2/slice';
import Modal from '../../../Common/CustomModal/Modal';

const PreviewAssessmentContent = () => {
  const dispatch = useDispatch();
  const { search } = useLocation();
  const assessmentId = new URLSearchParams(search).get('assessmentId');
  const history = useHistory();

  const {
    assessmentTitle,
    companyId,
    contentType,
    draftQuestionDetails,
    isCustom,
    language,
    seiority,
    universalPosition
  } = useSelector(detailObjSelector) || {};

  const regenerateAssessmentAvailability = useSelector(regenerateAssessmentAvailabilitySelector);
  const regenerateQuestionRemainingRight = useSelector(regenerateQuestionRemainingRightSelector);

  const [assessmentDuration, setAssessmentDuration] = useState();

  const [confirmButtonLoading, setConfirmButtonLoading] = useState(false);
  const [sharePreviewShow, setSharePreviewShow] = useState(false);
  const [showRegenerateAssessment, setShowRegenerateAssessment] = useState(false);
  const [willRegeneratedQuestionsList, setWillRegeneratedQuestionsList] = useState([]);

  const { t } = useTranslation(['assmCustomization'])

  const fetchDraftAssessmentDetail = () => {
    if (assessmentId) {
      dispatch(previewDraftAssessmentCheckAction({ RootAssessmentId: assessmentId }));
    }
  };

  const fetchRegenerateAvailability = () => {
    dispatch(regenerateAssessmentAvailabilityCheckAction());
    dispatch(regenerateQuestionAvailabilityCheckAction());
  };

  useEffect(() => {
    fetchRegenerateAvailability();
    fetchDraftAssessmentDetail();
  }, []);

  function totalTimeCalculator(qList) {
    if (!qList || qList.length === 0) return null;
    let totalDuration = 0;
    qList?.forEach(question => {
      const duration = Number(question?.time);
      totalDuration += duration;
    });
    const seconds = totalDuration % 60;
    const min = Math.floor(totalDuration / 60);
    // if (totalDuration > 60) return `${min} minutes ${seconds} seconds`;

    // return `${seconds} seconds`;

    if (totalDuration > 60)
      return (
        <Trans i18nKey="durationMinSec" ns="assmCustomization" values={{ min: min, sec: seconds }}>
          <span>1</span>
        </Trans>
      );
    return (
      <Trans i18nKey="durationSec" ns="assmCustomization" values={{ sec: seconds }}>
        <span>1</span>
        <span>2</span>
      </Trans>
    );
  }

  useEffect(() => {
    setAssessmentDuration(totalTimeCalculator(draftQuestionDetails));
  }, [draftQuestionDetails]);

  function sharePreview() {
    setSharePreviewShow(true);
  }

  function addToRegeneratedList(qId, comment) {
    setWillRegeneratedQuestionsList(prev => [...prev, { questionId: qId, comment: comment }]);
  }

  const removeFromRegeneratedList = qId => {
    const temp = [...willRegeneratedQuestionsList];
    temp.splice(qId, 1);
    setWillRegeneratedQuestionsList(temp);
  };

  const addAllToRegeneratedList = comment => {
    const temp = [];
    draftQuestionDetails?.forEach(x => {
      temp?.push({ questionId: x?.questionId, comment: comment });
    });

    setWillRegeneratedQuestionsList(temp);
  };

  const redirectToAddPosition = () => {
    dispatch(setAssessmentActiveTab(1));
    setTimeout(() => {
      history.push(route.addAssessment);
    }, 300);
  };

  const confirmButtonHandler = async () => {
    setConfirmButtonLoading(true);
    if (
      willRegeneratedQuestionsList?.length > 0 &&
      willRegeneratedQuestionsList?.length === draftQuestionDetails?.length
    ) {
      // regenerate assessment
      const data = {
        rootAssessmentId: assessmentId,
        comment: willRegeneratedQuestionsList[0].comment
      };
      const resp = await dispatch(regenerateAssessmentAction(data));
      if (resp?.meta?.requestStatus === 'fulfilled') {
        redirectToAddPosition();
      }
    } else if (willRegeneratedQuestionsList?.length > 0) {
      // regenerate question
      const data = {
        questionList: willRegeneratedQuestionsList,
        rootAssessmentId: assessmentId
      };
      const resp = await dispatch(regenerateQuestionsAction(data));
      if (resp?.meta?.requestStatus === 'fulfilled') {
        redirectToAddPosition();
      }
    } else {
      // publish
      const data = {
        rootAssessmentId: assessmentId
      };
      const resp = await dispatch(publishAssessmentAction(data));
      if (resp?.meta?.requestStatus === 'fulfilled') {
        redirectToAddPosition();
      }
    }
    setConfirmButtonLoading(false);
  };

  const DetailsWrapper = (
    <div className={styles.DetailsWrapper}>
      <span className={styles.Title}>{t('details')}</span>
      <div className={styles.DetailInnerBox}>
        <span className={styles.DetailInnerHeader}>{t('assmTitle')}</span>
        <span className={styles.DetailInnerContent}>{assessmentTitle}</span>
      </div>
      <div className={styles.DetailInnerBox}>
        <span className={styles.DetailInnerHeader}>{t('qCount')}</span>
        <span className={styles.DetailInnerContent}>{draftQuestionDetails?.length}</span>
      </div>
      <div className={styles.DetailInnerBox}>
        <span className={styles.DetailInnerHeader}>{t('assmDuration')}</span>
        {/* {willRegeneratedQuestionsList?.length > 0 ? (
          <span className={styles.DetailTimeCalculating}>recalculating..</span>
        ) : ( */}
        <span className={styles.DetailInnerContent}>{assessmentDuration}</span>
        {/* )} */}
      </div>
      <div className={styles.DetailButtonsWrapper}>
        <CustomButton
          textField={t('sendReview')}
          type={5}
          size="small"
          customStyle={{ width: '100%' }}
          buttonOnClick={() => sharePreview()}
        />
        <CustomButton
          textField={
            willRegeneratedQuestionsList?.length > 0 ? t('regenerateNSend') : t('publish')
          }
          loading={confirmButtonLoading}
          size="small"
          customStyle={{ width: '100%' }}
          buttonOnClick={confirmButtonHandler}
          isDisabled={draftQuestionDetails?.length === 0}
        />
      </div>
    </div>
  );

  const QuestionsWrapper = (
    <div className={styles.QuestionsWrapper}>
      <div className={styles.TitleRow}>
        <span className={styles.Title}>{t('questions')}</span>
        <CustomButton
          textField={t('regenerateAssm')}
          type={5}
          size="small"
          buttonOnClick={() => setShowRegenerateAssessment(true)}
          isDisabled={
            willRegeneratedQuestionsList?.length !== 0 || !regenerateAssessmentAvailability
          }
          tooltipText={
            !regenerateAssessmentAvailability
              ? t('exceedLimit')
              : undefined
          }
        />
      </div>
      <div className={styles.QuestionsHeader}>
        <div className={styles.QuestionHeaderInner}>
          <span>No.</span>
          <span>{t('title')}</span>
        </div>
        <span>{t('type')}</span>
      </div>
      {draftQuestionDetails?.map((question, index) => (
        <QuestionPreview
          key={`${question?.questionId}-${willRegeneratedQuestionsList?.length}`}
          questionDetail={question}
          index={index}
          willRegenerated={willRegeneratedQuestionsList?.some(
            x => x?.questionId === question?.questionId
          )}
          setRegenerated={comment => addToRegeneratedList(question?.questionId, comment)}
          isRegenerateEnabled={
            regenerateQuestionRemainingRight > willRegeneratedQuestionsList?.length
          }
          removeHandler={removeFromRegeneratedList}
          refetchList={fetchDraftAssessmentDetail}
        />
      ))}
    </div>
  );

  const blurCheck =
    assessmentTitle === null &&
    companyId === null &&
    draftQuestionDetails === null &&
    contentType === 0;

  const RegeneratingModal = (
    <Modal
      show={blurCheck}
      centerHorizontally
      centerVertically
      contentStyle={{ borderRadius: '16px', padding: '24px 48px', fontSize: '18px' }}
    >
      {t('aiCreating')}
    </Modal>
  );

  return (
    <>
      <div className={styles.ContentWrapper} style={blurCheck ? { filter: 'blur(10px)' } : {}}>
        {QuestionsWrapper}
        {DetailsWrapper}
      </div>
      <SharePreviewModal
        companyId={companyId}
        show={sharePreviewShow}
        onHide={() => setSharePreviewShow(false)}
      />
      <RegenerateModal
        show={showRegenerateAssessment}
        onClose={() => setShowRegenerateAssessment(false)}
        setAllAsRegenerated={addAllToRegeneratedList}
      />

      {RegeneratingModal}
    </>
  );
};

export default PreviewAssessmentContent;
