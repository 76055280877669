import React, {  } from 'react';
import styles from './Signup.module.css';
import InviteComponent from '../../Components/Signup/InviteComponent';

const RegistrationCompletePage = () => {

  return (
    <div className={styles.ContentBody}>
      <InviteComponent />
    </div>
  );
};

export default RegistrationCompletePage;
