import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { useTranslation, Trans } from 'react-i18next';

import CustomButton from '../../../../Common/Components/CustomButton';
import Skeleton from '../../../../Common/Components/Skeleton';
import UnlabeledSingleSelectDropdown from '../../../../Common/Components/UnlabeledSingleSelectDropdown/UnlabeledSingleSelectDropdown';
import { getPositionsForCurrentFunnelListAction } from '../../../../redux/candidateDetail/actions';
import {
  candidatePositionsSelector,
  currentAssessmentFunnelListSelector
} from '../../../../redux/candidateDetail/selectors';
import {
  setSelectedAssessmentForReport,
  setShowRejectionPopupFlag
} from '../../../../redux/candidateDetail/slice';
import { getParam, urlDynamicQueryUpdateLite } from '../../../../utils/helpers';
import { moveCandidateToFunnel, orderPositions, sortOnPriority } from '../helpers';
import styles from './AssessmentSummary.module.css';
import ProgressBar from './ProgressBar';

const AssessmentSummary = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [orderedListForDropdown, setOrderedListForDropdown] = useState();
  const candidatePositionsRedux = useSelector(candidatePositionsSelector);
  const [selectedValue, setSelectedValue] = useState();
  const currentAssessmentFunnelListRedux = useSelector(currentAssessmentFunnelListSelector);
  const [funnelListForDropdown, setFunnelListForDropdown] = useState();
  const [selectedFunnelValue, setSelectedFunnelValue] = useState();
  const [isSelectedFlag, setIsSelectedFlag] = useState(false);
  const [initFlagForFunnel, setInitFlagForFunnelInfo] = useState(true);

  const { t } = useTranslation(['candidateDetail']);

  /* Position dropdown related methods */
  // #region
  useEffect(() => {
    const orderedList = orderPositions(candidatePositionsRedux);

    const temp = [];
    orderedList.forEach(e =>
      temp.push({
        value: e.companyAssessmentId,
        label: e.reservedName,
        score: e.score,
        indAvg: e.allScoreAverage,
        currentFunnel: e.currentFunnel,
        addDate: e.addDate,
        arriveType: e.arriveType
      })
    );
    setOrderedListForDropdown(temp);
    setSelectedValue(
      temp.filter(e => e.value === getParam(location.search, 'currentAssessmentId'))[0]
    );
  }, [candidatePositionsRedux]);

  function setSelectedPosition() {
    setSelectedValue(
      orderedListForDropdown.filter(
        e => e.value === getParam(location.search, 'currentAssessmentId')
      )[0]
    );
  }

  useEffect(() => {
    if (orderedListForDropdown) {
      window.addEventListener('locationchange', setSelectedPosition);
    }
    return () => {
      window.removeEventListener('locationchange', setSelectedPosition);
    };
  }, [orderedListForDropdown]);
  // #endregion

  useEffect(() => {
    setIsSelectedFlag(false);
    setInitFlagForFunnelInfo(true);
    if (
      selectedValue &&
      currentAssessmentFunnelListRedux &&
      currentAssessmentFunnelListRedux?.length > 0
    ) {
      setInitFlagForFunnelInfo(true);
      let temp = [...currentAssessmentFunnelListRedux].sort(sortOnPriority);
      const isScored = selectedValue?.score !== -1;
      temp = temp.filter(e =>
        isScored
          ? e.funnelName !== 'Not Scored' && e.funnelName !== 'Rejected'
          : e.funnelName !== 'Scored' && e.funnelName !== 'Rejected'
      );
      const funnelListForDropdown = [];
      temp.forEach(e => funnelListForDropdown.push({ value: e.funnelId, label: e.funnelName }));
      setFunnelListForDropdown(funnelListForDropdown);

      if (selectedValue?.currentFunnel === 'Rejected') {
        const rejectedFunnel = currentAssessmentFunnelListRedux.filter(
          e => e.funnelName === selectedValue.currentFunnel
        )[0];
        setSelectedFunnelValue({
          value: rejectedFunnel.funnelId,
          label: rejectedFunnel.funnelName
        });
        setIsSelectedFlag(true);
      } else {
        setSelectedFunnelValue(
          funnelListForDropdown.filter(e => e.label === selectedValue.currentFunnel)[0]
        );
        setIsSelectedFlag(true);
      }
    }
  }, [currentAssessmentFunnelListRedux, selectedValue]);

  function moveCandidateToFunnelInner() {
    moveCandidateToFunnel(dispatch, id, selectedFunnelValue, false, t);
  }

  useEffect(() => {
    if (selectedFunnelValue) {
      if (!initFlagForFunnel) {
        moveCandidateToFunnelInner();
      } else {
        setInitFlagForFunnelInfo(false);
      }
    }
  }, [selectedFunnelValue]);

  function fetchFunnelListForCurrentAssessment() {
    const data = {
      companyAssessmentId: getParam(location.search, 'currentAssessmentId'),
      candidateId: id
    };
    dispatch(getPositionsForCurrentFunnelListAction(data));
  }

  useEffect(() => {
    fetchFunnelListForCurrentAssessment();
    window.addEventListener('locationchange', fetchFunnelListForCurrentAssessment);
    return () => {
      window.removeEventListener('locationchange', fetchFunnelListForCurrentAssessment);
    };
  }, []);

  useEffect(() => {
    dispatch(setSelectedAssessmentForReport(selectedValue));
  }, [selectedValue]);

  return (
    <div className={styles.AssessmentSummaryWrapper}>
      <div className="FlexRow spaceBetween verticallyCenter">
        <text className={styles.AssessmentSummaryHeader}>{t('assmSummary')}</text>
      </div>
      <div className={styles.AssessmentSummaryDropdownWrapper}>
        {orderedListForDropdown && (
          <UnlabeledSingleSelectDropdown
            list={orderedListForDropdown}
            selectedValue={selectedValue}
            setSelectedValue={e => {
              if (e.value !== getParam(location.search, 'currentAssessmentId')) {
                urlDynamicQueryUpdateLite('currentAssessmentId', e.value);
              }
              setSelectedValue(orderedListForDropdown.filter(x => x.value === e.value)[0]);
            }}
            placeholder={t('positions')}
          />
        )}
      </div>

      <div className={styles.AssessmentSummaryBottomWrapper}>
        <div className={styles.AssessmentSummaryBottomBox}>
          <text className={styles.BoxText}>{t('candScore')}</text>
          <ProgressBar
            score={selectedValue?.score}
            average={selectedValue?.indAvg}
            // tooltipText={`Industry average: ${selectedValue?.indAvg || 'NaN'}`}
            tooltipText={
              <span>
                <Trans
                  i18nKey="indAvgTooltip"
                  ns="candidateDetail"
                  values={{ indAvg: selectedValue?.indAvg || 'NaN' }}
                >
                  0<span>1</span>
                  <span>2</span>
                </Trans>
              </span>
            }
          />
        </div>
        <>
          <div className={styles.VerticalBorder} />
          <div className={styles.AssessmentSummaryBottomBox}>
            <text className={styles.BoxText}>{t('hiringStatus')}</text>
            <div className={styles.AssessmentSummaryFunnelWrapper}>
              {isSelectedFlag ? (
                <>
                  <UnlabeledSingleSelectDropdown
                    list={funnelListForDropdown}
                    selectedValue={selectedFunnelValue}
                    setSelectedValue={setSelectedFunnelValue}
                    placeholder={t('stages')}
                    wrapperStyle={{ width: 'calc(100% - 108px)' }}
                  />

                  {selectedFunnelValue?.label === 'Rejected' ? (
                    <CustomButton
                      buttonId="positionRejectButton"
                      type={5}
                      textField={t('reactivateButton')}
                      size="small"
                      style={{
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                        marginLeft: '18px',
                        height: '42px'
                      }}
                      buttonOnClick={() =>
                        moveCandidateToFunnel(
                          dispatch,
                          id,
                          funnelListForDropdown.filter(funnel =>
                            selectedValue?.score === -1
                              ? funnel.label === 'Not Scored'
                              : funnel.label === 'Scored'
                          )[0],
                          false,
                          t
                        )
                      }
                    />
                  ) : (
                    <CustomButton
                      type={6}
                      textField={t('rejectButton')}
                      size="small"
                      style={{ marginLeft: '18px', height: '42px' }}
                      // isDisabled={selectedFunnelValue?.label === 'Rejected'}
                      buttonOnClick={() => dispatch(setShowRejectionPopupFlag(true))}
                    />
                  )}
                </>
              ) : (
                <Skeleton type="funnelDropdown" />
              )}
            </div>
          </div>
        </>
      </div>
    </div>
  );
};
export default AssessmentSummary;
