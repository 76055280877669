import API from '../../constant/apiVol2';
import client from '../../constant/client';

export const changeCompanyService = data =>
  new Promise((resolve, reject) => {
    client.post(API.changeCompany, data).then(resolve).catch(reject);
  });

export const getTeamService = data =>
  new Promise((resolve, reject) => {
    client.get(API.getTeam, { params: data }).then(resolve).catch(reject);
  });

export const makeAdminService = data =>
  new Promise((resolve, reject) => {
    client.post(API.makeAdmin, data).then(resolve).catch(reject);
  });

export const removeFromAdminsService = data =>
  new Promise((resolve, reject) => {
    client.post(API.removeFromAdmins, data).then(resolve).catch(reject);
  });

export const editTeamMemberService = data =>
  new Promise((resolve, reject) => {
    client.post(API.editTeamMember, data).then(resolve).catch(reject);
  });

export const deleteTeamMemberService = data =>
  new Promise((resolve, reject) => {
    client.post(API.deleteTeamMember, data).then(resolve).catch(reject);
  });

  export const getCurrentUserDetailService = data =>
  new Promise((resolve, reject) => {
    client.get(API.getCurrentUserDetail, data).then(resolve).catch(reject);
  });

  export const getCompanyListService = data =>
  new Promise((resolve, reject) => {
    client.get(API.getCompanyList, { params: data }).then(resolve).catch(reject);
  });