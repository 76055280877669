import React, { useState, useCallback, useEffect } from 'react';
import MailOTPVerification from '../Components/MailOTPVerification/MailOTPVerification';

const useMailOTPVerification = (
  isRequired,
  application,
  hasInformation,
  isScreeningNeeded,
  isCvNeeded
) => {
  const [isVerified, setIsVerified] = useState(!isRequired);

  useEffect(() => {
    setIsVerified(!isRequired);
  }, [isRequired]);

  useEffect(() => {
    if (isScreeningNeeded === false && isCvNeeded === false) {
      setIsVerified(true);
    }
  }, [isScreeningNeeded, isCvNeeded]);

  useEffect(() => {
    if (hasInformation === false) {
      setIsVerified(true);
    }
  }, [hasInformation]);

  const MailOTPHandler = useCallback(() => {
    return <MailOTPVerification application={application} setIsVerified={setIsVerified} />;
  }, [isVerified]);

  return { isVerified, MailOTPHandler };
};

export default useMailOTPVerification;
