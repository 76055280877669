import React, { useEffect, useState } from 'react';
import styles from '../Styles/EmailText.module.css';
import isEmail from '../../utils/isEmail';

const EmailText = ({
  placeholder,
  value,
  setValue,
  inputChange,
  onEnterDown,
  isDisabled,
  style,
  errorDisable, // to pass the error to  parent
  setErrorDisable = () => {}, // to pass the error to  parent
  // validitityError = false, // to pass backend errors
  showInvisibleError, // To prevent height change on error show - just changes its visibility if this flag exists
  errorMessage, // Error message - passed from parent
  trimWhiteSpaces, // Automatically removes spaces as typed - if passed
  name, // Optional html name attribute
}) => {
  const [error, setError] = useState(false);
  const [id, setId] = useState(-1);

  useEffect(() => {
    if (errorMessage && value && value !== '') {
      setErrorDisable(true);
      setError(true);
    }
  }, [errorMessage]);

  return (
    <div
      id="container"
      className={`${styles.container} ${error && styles.errorInput} `}
      style={isDisabled ? { ...style, pointerEvents: 'none', opacity: '0.4' } : style}
    >
      <input
        className={value && styles.hasValue}
        autoComplete={false}
        onChange={event => {
          if (trimWhiteSpaces && event.nativeEvent.data === ' ') {
            // Do nothing if space is pressed
          } else {
            setValue(event.target.value.toLocaleLowerCase('tr'));
            if (id !== -1) {
              clearTimeout(id);
              setId(-1);
            }
            setId(
              setTimeout(() => {
                if (isEmail(event.target.value) || event.target.value === '') {
                  if (errorDisable) {
                    setErrorDisable(false);
                  }
                  setError(false);
                } else {
                  if (errorDisable) {
                    setErrorDisable(true);
                  }
                  setError(true);
                }
              }, 1000)
            );

            if (inputChange) {
              inputChange(event);
            }
          }
        }}
        value={value}
        type="new-password"
        onKeyDown={e => {
          if (onEnterDown && e.key === 'Enter') {
            onEnterDown();
          }
          if (trimWhiteSpaces && e.code === 'Space') {
            e.preventDefault();
          }
        }}
        disabled={isDisabled}
        name={name}
      />
      <label id="label" className={value && styles.filled}>
        {placeholder}
      </label>
      {((error && errorMessage) || showInvisibleError) && (
        <div
          className={styles.errorMessageWrapper}
          style={{
            visibility: showInvisibleError
              ? error || errorMessage
                ? 'visible'
                : 'hidden'
              : 'visible'
          }}
        >
          {errorMessage || <>&nbsp;</>}
        </div>
      )}
    </div>
  );
};

export default EmailText;
