import React, { useLayoutEffect, useState } from 'react';

import { useTranslation, Trans } from 'react-i18next';

import styled from 'styled-components';
import { addCandidatesToFunnelAction } from '../redux/assessmentDetailVol2/actions';
import {
  setBlurCandidateWrapperAfterMoveFlag,
  setClickedFunnel,
  setInfoBannerErrorFlag,
  setInfoBannerShowState,
  setInfoBannerText,
  setMailModalClickedFunnelInfo,
  setPopupShowState,
  setPopupTypeState,
  setShowMailModal
} from '../redux/assessmentDetailVol2/slice';
import { getRootAssessmentListActionVol2 } from '../redux/assessmentsVol2/actions';
import {
  setFilteredRootAssessmentList,
  setMostPopularRootAssessmentList
} from '../redux/assessmentsVol2/slice';
import constants from './constants';

export const getOnlyDomain = () => process.env.REACT_APP_MAIN_DOMAIN;

export const getOnlyDomainNoPort = () => process.env.REACT_APP_MAIN_DOMAIN.replace(':3000', '');

export const getDomainProtocol = () => process.env.REACT_APP_MAIN_FULL_DOMAIN.split('://')[0];

export const getFullDomain = () => process.env.REACT_APP_MAIN_FULL_DOMAIN;

export const isLocalhost = () =>
  getOnlyDomain().includes('127.0.0.1') || getOnlyDomain().includes('localhost');

export const logoutAndRedirect = () => {
  localStorage.removeItem('authToken');
  localStorage.removeItem('user');
  localStorage.removeItem('activeCompany');
  localStorage.removeItem('authTokenVol2');
  localStorage.removeItem('Banner');
  window.location.href = `${getDomainProtocol()}://app.${getOnlyDomain()}/login`;
};
export function isJsonString(str) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}
export const getAssessmentStatusClass = item => {
  let className = 'active';
  switch (item) {
    case 'Active':
      className = 'primary';
      break;
    case 'Archived':
      className = 'secondary';
      break;
    case 'Time To Hire':
      className = 'success';
      break;
    case 'Average Rating':
      className = 'warning';
      break;
    case 'Wait For More':
      className = 'danger';
      break;

    default:
      break;
  }

  return className;
};

export function useWindowSize() {
  // Use this hook to update the bounding box in the parent!
  const [size, setSize] = useState([0, 0]);

  useLayoutEffect(() => {
    let debounceTimeout;

    function updateSize(withDelay = false) {
      if (withDelay) {
        clearTimeout(debounceTimeout);
        debounceTimeout = setTimeout(() => {
          setSize([window.innerWidth, window.innerHeight]);
        }, 200); // Adjust the debounce delay as needed
      } else {
        setSize([window.innerWidth, window.innerHeight]);
      }
    }

    window.addEventListener('resize', () => updateSize(true));
    updateSize();

    return () => {
      clearTimeout(debounceTimeout);
      window.removeEventListener('resize', () => updateSize(true));
    };
  }, []);

  return size;
}

// Assessment flow akışındaki constantlar
export const ASSESSMENT_FLOW_MOBILE_THRESHOLD = 576;
export const ASSESSMENT_FLOW_CODING_MOBILE_THRESHOLD = 756;

export async function fetchRootAssessmentList(data, setLoading = () => {}, ref, dispatch) {
  setLoading(true);
  ref.current = Date.now();
  const local = ref.current;

  const resp = await dispatch(getRootAssessmentListActionVol2(data));

  if (local === ref.current && resp.meta.requestStatus === 'fulfilled') {
    dispatch(setFilteredRootAssessmentList(resp.payload.rootAssesments));
    dispatch(setMostPopularRootAssessmentList(resp.payload.popularAssesments));
    setLoading(false);
  }
}

export function urlAddQuery(baseUrl, queryParameters, invokeEvent = true) {
  /* 
    Updates current url without page navigation or reload
  */
  const newUrl = baseUrl + queryParameters;
  window.history.replaceState({ path: newUrl }, '', newUrl);
  // dispatching an event inside redux slicer causes error
  // that is why it can be disabled by providing invokeEvent parameter with false
  if (invokeEvent) {
    window.dispatchEvent(new Event('locationchange'));
  }
}

export function getParam(queryParam, paramName) {
  /* 
    Gets the value of paramName in query parameter
  */
  if (queryParam.includes(`${paramName}=`)) {
    const splittedQuery = queryParam.split(`${paramName}=`);
    const removeAmpersand = splittedQuery[1].split('&');
    return removeAmpersand[0];
  }
  return null;
}

export function urlDynamicQueryUpdate(parameterName, parameterValue) {
  /* 
    !!! Supports null check on parameterValue and removal on nulls !!!
    parameterName: name of the parameter - if included it will be updated, otherwise will be added
    parameterValue: value of the parameter - if null, parameter will be removed
  */
  const { location } = window;
  const staticUrl = location.origin + location.pathname;
  const queryParams = location.search;

  // if there is no parameters yet, and parameterValue is given, add it to url
  if (queryParams === '') {
    if (parameterValue) {
      urlAddQuery(staticUrl, `?${parameterName}=${parameterValue}`);
      return;
    }
  }

  const currentVal = getParam(queryParams, parameterName);

  // if given parameterName is not included in queryParams and parameterValue is given
  // add it to url
  if (!currentVal && parameterValue) {
    urlAddQuery(staticUrl, `${queryParams}&${parameterName}=${parameterValue}`);
    return;
  }

  // if given parameter is included in queryParams and parameterValue is given
  // replace previous value with new one
  if (currentVal && parameterValue) {
    let newQueryParams = queryParams;
    newQueryParams = newQueryParams.replace(
      `${parameterName}=${currentVal}`,
      `${parameterName}=${parameterValue}`
    );
    urlAddQuery(staticUrl, newQueryParams);
    return;
  }

  // if given parameter is included in queryParams but parameterValue is null
  // remove previous value
  if (currentVal && !parameterValue) {
    let newQueryParams = queryParams;
    if (queryParams.includes(`&${parameterName}=${currentVal}`)) {
      newQueryParams = newQueryParams.replace(`&${parameterName}=${currentVal}`, '');
    } else if (queryParams.includes(`?${parameterName}=${currentVal}&`)) {
      newQueryParams = newQueryParams.replace(`?${parameterName}=${currentVal}&`, '?');
    } else if (queryParams.includes(`?${parameterName}=${currentVal}`)) {
      newQueryParams = newQueryParams.replace(`?${parameterName}=${currentVal}`, '');
    }

    urlAddQuery(staticUrl, newQueryParams);
  }
}

export function urlDynamicQueryUpdateLite(parameterName, parameterValue, invokeEvent = true) {
  /* 
    !!! Does not support null check on parameterValue and removal !!!
    parameterName: name of the parameter - if included it will be updated, otherwise will be added
    parameterValue: value of the parameter
  */
  const { location } = window;
  const staticUrl = location.origin + location.pathname;
  const queryParams = location.search;

  // if there is no parameters yet, and parameterValue is given, add it to url
  if (queryParams === '') {
    urlAddQuery(staticUrl, `?${parameterName}=${parameterValue}`, invokeEvent);
    return;
  }

  const currentVal = getParam(queryParams, parameterName);

  // if given parameterName is not included in queryParams and parameterValue is given
  // add it to url
  if (currentVal === null) {
    urlAddQuery(staticUrl, `${queryParams}&${parameterName}=${parameterValue}`, invokeEvent);
    return;
  }

  // if given parameter is included in queryParams and parameterValue is given
  // replace previous value with new one
  if (currentVal !== null) {
    let newQueryParams = queryParams;
    newQueryParams = newQueryParams.replace(
      `${parameterName}=${currentVal}`,
      `${parameterName}=${parameterValue}`
    );
    urlAddQuery(staticUrl, newQueryParams, invokeEvent);
  }
}

export function checkObjectEquality(obj1, obj2) {
  if (Object.keys(obj1).length !== Object.keys(obj2).length) {
    return false;
  }
  let flag = true;
  Object.keys(obj1).forEach(obj1Key => {
    if (obj1[obj1Key] !== obj2[obj1Key]) {
      flag = false;
    }
  });
  return flag;
}

export function findIndexOfObjectInList(list, obj) {
  let index = -1;
  list.every((e, i) => {
    if (checkObjectEquality(e, obj)) {
      index = i;
      return false;
    }
    return true;
  });
  return index;
}

export function candidateFindIndex(selectedCandidateList, candidate) {
  /* Specific usage for selectedCandidateListSelector */
  // let index = -1;
  // selectedCandidateList.every((e, i) => {
  //   if (e.userId === candidate.userId) {
  //     index = i;
  //     return false;
  //   }
  //   return true;
  // });

  const index = selectedCandidateList.findIndex(e => e?.userId === candidate?.userId);
  return index;
}

export const LoadingWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

const dateDict = {
  '01': 'january',
  '02': 'february',
  '03': 'march',
  '04': 'april',
  '05': 'may',
  '06': 'june',
  '07': 'july',
  '08': 'august',
  '09': 'september',
  10: 'october',
  11: 'november',
  12: 'december'
};

export function dateFormatter(givenDate, relativityCheck = false, translate) {
  /* 
    Assumes that givenDate is in "2023-01-10 06:48:11" format
    Converts it into "January 10, 2023" format
    If relativityCheck is given labels Today and Yesterday accordingly
  */
  let wholePart = [];
  if (givenDate?.includes('/')) {
    wholePart = givenDate.split(' ')[0].split('/');
  } else {
    wholePart = givenDate.split(' ')[0].split('-');
  }

  if (!relativityCheck) {
    return `${translate(dateDict[wholePart[1]])} ${wholePart[2]}, ${wholePart[0]}`;
  }

  const { t } = useTranslation(['dateDict, candidateDetail']);

  const currentDate = new Date();
  let monthPart = '';
  let datePart = '';
  let datePartForYesterday = '';

  monthPart = `${currentDate.getMonth() + 1 < 10 ? '0' : ''}${currentDate.getMonth() + 1}`;
  datePart = `${currentDate.getDate() < 10 ? '0' : ''}${currentDate.getDate()}`;
  datePartForYesterday = `${currentDate.getDate() - 1 < 10 ? '0' : ''}${currentDate.getDate() - 1}`;

  const dateArr = [datePart, monthPart, `${currentDate.getFullYear()}`];

  if (currentDate.getFullYear().toString() === wholePart[0].toString()) {
    if (monthPart === wholePart[1].toString()) {
      if (datePart === wholePart[2].toString()) {
        return translate(t('today'));
      }
      if (datePartForYesterday === wholePart[2].toString()) {
        return translate(t('yesterday'));
      }
    }
  }

  return `${translate(dateDict[wholePart[1]])} ${wholePart[2]}, ${wholePart[0]}`;
}

export async function moveCandidateToFunnel(
  funnelInfo,
  companyAssessmentId,
  userIdArray,
  dispatch,
  selectedFunnelId,
  t,
  disableInfoBanner = false,
  rejectionReasonId = null
) {
  const data = {
    funnelId: funnelInfo.funnelId,
    companyAssessmentId: companyAssessmentId,
    userIds: userIdArray,
    rejectionReasonId: rejectionReasonId
  };
  const resp = await dispatch(addCandidatesToFunnelAction(data));
  if (resp?.meta?.requestStatus === 'fulfilled') {
    // window.dispatchEvent(new Event('disableSelectAll'));
    if (selectedFunnelId !== 'ZC0MuB7LayTJl0Bet0PL4g%3d%3d') {
      /* No need for request on All Candidates */
      dispatch(setBlurCandidateWrapperAfterMoveFlag(true));
      window.dispatchEvent(new Event('moveCandidateToFunnel'));
    } else {
      /* On All Candidates, just reset selected candidates */
      window.dispatchEvent(new Event('disableSelectAll'));
    }
    if (!disableInfoBanner) {
      dispatch(setInfoBannerShowState(true));
      if (userIdArray.length > 1) {
        dispatch(
          setInfoBannerText(
            t('movedToFunnelPlural', {
              funnelName: funnelInfo.funnelCategory === 20 ? 'New' : funnelInfo.funnelName
            })
          )
        );
      } else {
        dispatch(
          setInfoBannerText(
            t('movedToFunnel', {
              funnelName: funnelInfo.funnelCategory === 20 ? 'New' : funnelInfo.funnelName
            })
          )
        );
      }

      dispatch(setInfoBannerErrorFlag(false));
    }

    return true;
  }
  if (!disableInfoBanner) {
    dispatch(setInfoBannerShowState(true));
    dispatch(setInfoBannerText(t('errorOccured')));
    dispatch(setInfoBannerErrorFlag(true));
  }

  return false;
}

export async function candidateReportMoveCandidateToFunnel(
  funnelInfo,
  companyAssessmentId,
  userId,
  dispatch
) {
  /* Only use in candidate report page */
  const data = {
    funnelId: funnelInfo.funnelId,
    companyAssessmentId: companyAssessmentId,
    userIds: [userId]
  };
  await dispatch(addCandidatesToFunnelAction(data));
}

export function moveCandidateToFunnelPopup(
  clickedFunnelInfo,
  selectedCandidateList,
  dispatch,
  compAssessmentId,
  selectedFunnelId,
  t,
  isMobile = false
) {
  /**
   * TODO: Original logic is altered for Brisa demo - replace the new block
   * with original commented block
   */

  // if (selectedCandidateList?.length > 1) {
  //   if (clickedFunnelInfo.funnelCategory === 20) {
  //     dispatch(setPopupTypeState('Move-New'));
  //   } else {
  //     dispatch(setPopupTypeState('Move-NotNew'));
  //   }
  //   dispatch(setClickedFunnel(clickedFunnelInfo));
  //   dispatch(setPopupShowState(true));
  // } else {
  //   moveCandidateToFunnel(
  //     clickedFunnelInfo,
  //     compAssessmentId,
  //     selectedCandidateList?.map(e => e.userId),
  //     dispatch,
  //     selectedFunnelId
  //   );
  // }

  if (clickedFunnelInfo?.funnelCategory === 20) {
    if (selectedCandidateList?.length > 1) {
      dispatch(setPopupTypeState('Move-New'));
      dispatch(setClickedFunnel(clickedFunnelInfo));
      dispatch(setPopupShowState(true));
    } else {
      moveCandidateToFunnel(
        clickedFunnelInfo,
        compAssessmentId,
        selectedCandidateList?.map(e => e.userId),
        dispatch,
        selectedFunnelId,
        t
      );
    }
  } else if (isMobile) {
    if (selectedCandidateList?.length > 1) {
      dispatch(setPopupTypeState('Move-NotNew'));
      dispatch(setClickedFunnel(clickedFunnelInfo));
      dispatch(setPopupShowState(true));
    } else {
      moveCandidateToFunnel(
        clickedFunnelInfo,
        compAssessmentId,
        selectedCandidateList?.map(e => e.userId),
        dispatch,
        selectedFunnelId,
        t
      );
    }
  } else {
    // Show new modal
    dispatch(setMailModalClickedFunnelInfo(clickedFunnelInfo));
    dispatch(setShowMailModal(true));
  }
}

export function currencyEnumSelector(currencyString) {
  /*
      {id: 10, name: "USD"}
      {id: 20, name: "TRY"}
      {id: 30, name: "EUR"}
      {id: 40, name: "GBP"}
    */
  let enumVal;
  switch (currencyString) {
    case 'USD':
      enumVal = 10;
      break;
    case 'TRY':
      enumVal = 20;
      break;
    case 'EUR':
      enumVal = 30;
      break;
    case 'GBP':
      enumVal = 40;
      break;
    default:
      enumVal = 20;
      break;
  }

  return enumVal;
}

export function resetFiltersForFunnelClick(currency = false) {
  const currencyEnumVal = currencyEnumSelector(currency);

  urlDynamicQueryUpdateLite('orderType', '3', false);
  urlDynamicQueryUpdateLite('startDate', '', false);
  urlDynamicQueryUpdateLite('endDate', '0', false);
  urlDynamicQueryUpdateLite('startScore', '0', false);
  urlDynamicQueryUpdateLite('endScore', '100', false);
  urlDynamicQueryUpdateLite('arriveType', '', false);
  urlDynamicQueryUpdateLite('searchParam', '', false);
  urlDynamicQueryUpdateLite('industryList', '', false);
  urlDynamicQueryUpdateLite('schoolList', '', false);
  urlDynamicQueryUpdateLite('majorList', '', false);
  urlDynamicQueryUpdateLite('industryIncludeType', '20', false);
  urlDynamicQueryUpdateLite('experiences', '', false);
  urlDynamicQueryUpdateLite('workTypes', '', false);
  urlDynamicQueryUpdateLite('workPermit', '', false);
  urlDynamicQueryUpdateLite('location', '', false);
  urlDynamicQueryUpdateLite('currency', currencyEnumVal, false);
  urlDynamicQueryUpdateLite('minSalary', '', false);
  urlDynamicQueryUpdateLite('maxSalary', '', false);
  urlDynamicQueryUpdateLite('rejectReason', '', false);
}

export function getHeaderHeight() {
  const [headerH, setHeaderH] = useState();

  useLayoutEffect(() => {
    function updateWindow() {
      const height = document.getElementById('header')?.getBoundingClientRect()?.height;
      setHeaderH(height);
    }

    window.addEventListener('headerUpdate', updateWindow);
    updateWindow();
    return () => window.removeEventListener('headerUpdate', updateWindow);
  }, []);

  return headerH;
}

export const calculateMin = timeInit => {
  const timeInSeconds = parseInt(timeInit, 10);
  const time = {
    min: Math.floor(timeInSeconds / 60),
    sec: timeInSeconds - Math.floor(timeInSeconds / 60) * 60
  };

  if (time.sec === 0) {
    // return `${time.min} min`;
    return (
      <span>
        <Trans i18nKey="timeMin" ns="questionBasedAssm" values={{ min: time.min }}>
          0<span>1</span>
          <span>2</span>
        </Trans>
      </span>
    );
  }
  if (time.min === 0) {
    // return `${time.sec} sec`;
    return (
      <span>
        <Trans i18nKey="timeSec" ns="questionBasedAssm" values={{ sec: time.sec }}>
          0<span>1</span>
          <span>2</span>
        </Trans>
      </span>
    );
  }
  // return `${time.min} min ${time.sec} sec`;
  return (
    <span>
      <Trans i18nKey="timeMinSec" ns="questionBasedAssm" values={{ min: time.min, sec: time.sec }}>
        0<span>1</span>
        <span>2</span>
        <span>3</span>
        <span>4</span>
      </Trans>
    </span>
  );
};

export function renderDescription(qDesc) {
  let innerDesc = qDesc;
  innerDesc = innerDesc?.replaceAll('<br />', '');
  innerDesc = innerDesc?.replaceAll('<br>', '');
  const arr = Array(20)
    .fill(null)
    .map((_, i) => i + 8);
  arr?.forEach(x => {
    innerDesc = innerDesc?.replaceAll(`font-size: ${x}px;`, '');
  });
  innerDesc = innerDesc?.replaceAll('<p class="', '<p class="ellipsis ');
  innerDesc = innerDesc?.replaceAll('<p>', '<p class="ellipsis pt-5">');

  return (
    <div
      className="ellipsis font-14"
      style={{ height: '35px', maxHeight: '35px' }}
      dangerouslySetInnerHTML={{ __html: innerDesc }}
    />
  );
}

/**
 * Takes a base64 encoded string and returns a binary file.
 * @param {*} base64String : Base64 encoded string
 * @param {*} contentType : content type, i.e."image/jpg"
 * @returns
 */
export const base64ToBlob = (base64String, contentType) => {
  // Remove base64 URL prefix if present
  const base64Data = base64String.replace(/^data:([A-Za-z-+/]+);base64,/, '');

  // Convert base64 to raw binary data held in a string
  // Instead of atob, use the modern approach with ArrayBuffer and Uint8Array
  const byteCharacters = Uint8Array.from(atob(base64Data), c => c.charCodeAt(0));

  // Create a blob directly from the Uint8Array
  const blob = new Blob([byteCharacters], { type: contentType });
  return blob;
};

export function convertArriveTypeFromFilter(arriveTypesFromDb) {
  /* eslint-disable */
  const temp = [];
  if (arriveTypesFromDb?.includes(constants.CANDIDATE_ARRIVE_TYPES['CandidateAutoApply'])) {
    temp.push(0);
  }
  if (arriveTypesFromDb?.includes(constants.CANDIDATE_ARRIVE_TYPES['Email'])) {
    temp.push(1);
  }
  if (
    arriveTypesFromDb?.includes(
      constants.CANDIDATE_ARRIVE_TYPES['Other'] &&
        constants.CANDIDATE_ARRIVE_TYPES['LinkedIn'] &&
        constants.CANDIDATE_ARRIVE_TYPES['KariyerIntegration'] &&
        constants.CANDIDATE_ARRIVE_TYPES['WelcomePageLink'] &&
        constants.CANDIDATE_ARRIVE_TYPES['ApplicationLink'] &&
        constants.CANDIDATE_ARRIVE_TYPES['SuccessFactors'] &&
        constants.CANDIDATE_ARRIVE_TYPES['AlreadySolvedTransfer'] &&
        constants.CANDIDATE_ARRIVE_TYPES['KariyerAts']
    )
  ) {
    temp.push(2);
  }
  return temp;
}

export function convertArriveTypesForFilter(arriveTypesFromUI) {
  return arriveTypesFromUI?.length > 0
    ? [...arriveTypesFromUI].map(x => constants.SAVE_FILTER_ARRIVE_TYPE_INDEX_MAPPING[x]).flat()
    : null;
}

export function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}
