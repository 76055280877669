import { React, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import CloseButton from '../../../../../Common/Components/CloseButton';
import CustomButton from '../../../../../Common/Components/CustomButton';
import SearchBar from '../../../../../Common/Components/SearchBar';
import Checkbox from '../../../../../Common/Components/Checkbox';
import copyLink from '../../../../../images/Common/copyLink.svg';
import linkCopiedImg from '../../../../../images/Common/linkCopied.svg';
import invited from '../../../../../images/Common/invited.svg';
import styles from './SharePreviewModal.module.css';
import {
  userList,
  customAssessmentId,
  invitedHMList,
  inviteUserShow,
  newlyInvitedIDList,
  allUserListSelector
} from '../../../../../redux/assessmentsVol2/selectors';
import {
  getUserListByCompanyAction,
  sendEmailForReviewAction
} from '../../../../../redux/assessmentsVol2/actions';
import { userSelector } from '../../../../../redux/auth/selectors';
import { ReactComponent as Success } from '../../../../../images/Common/SFR_success.svg';
import InviteUserModal from '../../../../AddAssessment/Modals/InviteUserModal';
import { getParam } from '../../../../../utils/helpers';
import Modal from '../../../../../Common/CustomModal/Modal';

export default function SharePreviewModal({ show, onHide, companyId, ...props }) {
  const [selectedUserList, setSelectedUserList] = useState([]);
  const invitedHRlist = useSelector(invitedHMList);

  const newlyInvitedList = useSelector(newlyInvitedIDList);

  const [searchParam, setSearchParam] = useState('');
  const [copySuccess, setCopySuccess] = useState(false);
  const user = useSelector(userSelector);
  const dispatch = useDispatch();
  const id = useSelector(customAssessmentId); // CHANGE
  const rootAssmId = getParam(location.search, 'assessmentId');;

  const [inviteShow, setInviteUserShow] = useState(false);

  const companyUserList = useSelector(allUserListSelector);
  const newUserList = useSelector(userList);
  const [success, setSuccess] = useState(false);

  const { t } = useTranslation(['assmCustomization'])

  const copyToClipBoard = copyMe => {
    try {
      navigator.clipboard.writeText(copyMe);
      setCopySuccess(true);
    } catch (err) {
      setCopySuccess(false);
    }
  };

  useEffect(() => {
    if (user && show) {
      dispatch(getUserListByCompanyAction({ CompanyId: companyId })); // CHANGE ??
    }
    return () => {
      setSelectedUserList([]);
      setSuccess(false);
    };
  }, [user, show]);

  function handleCheckbox(hr) {
    let temp = [...selectedUserList];
    if (selectedUserList.includes(hr)) {
      temp = temp.filter(e => e !== hr);
      setSelectedUserList(temp);
    } else {
      setSelectedUserList(prev => [...prev, hr]);
    }
  }

  const [emailsReady, setEmailsReady] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);

  async function sendMails() {
    const link = window.location.href;
    const response = await dispatch(
      sendEmailForReviewAction({
        emails: selectedUserList,
        link,
        rootAssessmentId: rootAssmId
      })
    );

    if (response.payload) {
      setSuccess(true);
    }
    setEmailsReady(false);

    setButtonLoading(false);
  }

  useEffect(() => {
    setButtonLoading(false);
  }, []);

  async function handleInviteHRForReview() {
    setButtonLoading(true);
    if (newlyInvitedList?.length > 0) {
      await newlyInvitedList?.forEach(id => {
        const email = newUserList?.filter(user => user?.id === id)[0]?.email;
        setSelectedUserList(prev => [...prev, email]);
      });
    }
    await setEmailsReady(true);
  }

  useEffect(() => {
    if (emailsReady) {
      sendMails();
    }
  }, [emailsReady]);

  const modalClose = () => {
    onHide();
    setCopySuccess(false)
  }

  return (
    <Modal
      contentClassName={`${styles['modal-content']}`}
      onClose={modalClose}
      show={show}
      centerHorizontally
      centerVertically
      outsideClickClose
    >
      <InviteUserModal show={inviteShow} onHide={() => setInviteUserShow(false)} createAssessment />
      <div className={`${styles.ModalWrapper} ${inviteShow && styles.blurred}`}>
        <div className={styles.header}>
          {!success && <div className={styles.headerTitle}>{t('sendReview')}</div>}
          <CloseButton onClick={modalClose} size="small" />
        </div>

        {success ? (
          <>
            <div className={styles.successContainer}>
              <Success className={styles.successIcon} />
              <div className={styles.successTitle}>{t('successSent')}</div>
              <div className={styles.successSubtitle}>
                {t('invReview')}
              </div>
            </div>
          </>
        ) : (
          <>
            <div className={styles.lineWrapper}>
              <span className={styles.textNormal}>{t('selectUser')}</span>
              <button
                className={styles.inviteButton}
                type="button"
                onClick={() => setInviteUserShow(true)}
              >
                {t('addUser')}
              </button>
            </div>
            <div className={styles.searchWrapper}>
              <SearchBar
                size="xsmall"
                isExtended={1}
                color="gray"
                inputFieldPlaceholder={t('search')}
                inputFieldOnChange={event => {
                  setSearchParam(event.target.value);
                }}
                inputFieldValue={searchParam}
                extendedStyle={{ width: '100%', fontSize: '14px' }}
              />
            </div>
            <div className={styles.hrList}>
              {companyUserList?.map(
                (user, key) =>
                  user.nameSurname
                    .toString()
                    .toLowerCase()
                    .includes(searchParam.toLocaleLowerCase()) && (
                    <div className={styles.checkboxRow}>
                      <div className={styles.checkboxWrapper}>
                        <Checkbox
                          myOnClick={() => handleCheckbox(user.email)}
                          myChecked={
                            selectedUserList.includes(user.email) ||
                            newlyInvitedList.includes(user.id)
                          }
                          myDisabled={newlyInvitedList.includes(user.id)}
                        />
                      </div>
                      <div
                        className={`${styles.hrText} ${
                          newlyInvitedList.includes(user.id) && styles.disabledHR
                        }`}
                        key={key}
                      >
                        {user.nameSurname}
                      </div>
                      {newlyInvitedList.includes(user.id) && styles.disabledHR && (
                        <div className={styles.invitedWrapper}>
                          <span className={styles.imgWrapper}>
                            <img alt="" src={invited} />
                          </span>
                          <span className={styles.invitedText}>{t('userInvSent')}</span>
                        </div>
                      )}
                      {JSON.stringify(invitedHRlist).includes(user.id) &&
                        !newlyInvitedList.includes(user.id) && (
                          <div className={styles.invitedWrapper}>
                            <span className={styles.imgWrapper}>
                              <img alt="" src={invited} />
                            </span>
                            <span className={styles.invitedText}>{t('invited')}</span>
                          </div>
                        )}
                    </div>
                  )
              )}
            </div>
            <div className={styles.linkRow}>
              <div
                className={styles.copyLink}
                onClick={() => copyToClipBoard(window.location.href)}
              >
                <span className={styles.imgWrapper}>
                  <img
                    style={{ height: '100%', width: '100%' }}
                    alt=""
                    src={copySuccess ? linkCopiedImg : copyLink}
                  />
                </span>
                <span>{copySuccess ? t('linkCopied') : t('copyLink')}</span>
              </div>
              <div className={styles.sendButton}>
                <CustomButton
                  textField={t('send')}
                  buttonOnClick={async () => {
                    // eslint-disable-next-line no-restricted-syntax
                    handleInviteHRForReview();
                  }}
                  size="small"
                  isDisabled={selectedUserList.length === 0 && newlyInvitedList.length === 0}
                  loading={buttonLoading}
                />
              </div>
            </div>
          </>
        )}
      </div>
    </Modal>
  );
}
